class ActionOutput {
    constructor(data) {
        this.actionOutput = data.actionOutput;
        this.outputOrder = data.outputOrder;
        this.commonPhrase="";
        this.outputCategory = data.outputCategory;
        this.actionId = data.actionId;
    }
    static create(data) {
        return new ActionOutput(data);
    }
}

export default ActionOutput;