<template>
  <div>
    <table style="width: 100%;">
      <thead>
        <tr>
          <th></th>
          <th v-for="(columnLabel) in dialogParameter.getColumnLabels()" style="padding: 0 5px; text-align: center; font-weight: 600 !important;">{{ columnLabel }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(dialogParameterRow, index) in dialogParameter.getDialogRows()" :key="dialogParameterRow.id">
          <td>{{ formatRowLabel(dialogParameterRow.getRowLabel(), (index + 1)) }}</td>
          <td v-for="(dialogColumnValue, columnIndex) in dialogParameterRow.suppDataColumns" :key="columnIndex" style="text-align: left; margin-bottom: 10px;  margin-top: 10px; padding-bottom: 10px;">
            <supplementalDataControlComponent :key="index" :dialogParameterRowColumn="dialogColumnValue" :dialogRowLabel="dialogParameterRow.getRowLabel()" :currentStepAction="currentStepAction" 
            :focused="requireFocus(columnIndex,index)"  style="height: 30px;" />
          </td>
          <td v-if="index === dialogParameter.getDialogRows().length - 1 && dialogParameter.canAddRows()">
            <button @click="addNewRow(dialogParameter)">+</button>
          </td>
          <td v-if="index > 0 && dialogParameter.canRemoveRows(index)" >
            <button @click="removeRow(dialogParameter,index)">-</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import supplementalDataControlComponent from './supplementalDataControlComponent.vue'
import StepAction from '@/Client/modules/pbadmin/models/StepAction.js';
import DialogParameter from '@/Client/modules/pbadmin/models/DialogParameter.js';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    supplementalDataControlComponent,
  },
  props: {
    dialogParameter: {
      type: DialogParameter,
      required: true
    },
    currentStepAction: {
      type: StepAction,
      required: true
    },
    focus: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      forceFocus:false
    };
  },

  methods: {
    requireFocus(columnIndex, index){
    
      if (this.focus === false)
      {
        return false;
      }
      return (columnIndex+index)  <= 0 
    },
    formatRowLabel(label, index) {
      if (label) {
        return label.replace("{row}", index);
      }
      return "";
    },
  addNewRow() {
    this.forceFocus = true;
    this.dialogParameter.addDialogRow(uuidv4())

  },
  removeRow(dialogParameter, index) {
    this.dialogParameter.remvoeDialogRow(index);
  }

}
};
</script>
  <style scoped>
table {
  float: none;
    display: block;
    width: 50%;
}
.table-wrapper {
    margin-bottom: 20px;  /* Add some space between tables */
    border: 1px solid #ddd;  /* Optional: Add a border around each table for visual clarity */
}

th, td {
    width: auto;
    overflow: hidden;  /* This will hide text that would cause the column to expand */
    text-overflow: ellipsis;  /* This will show an ellipsis when the text overflows */
    white-space: nowrap;  /* This keeps the text on a single line */
}
</style>