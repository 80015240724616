import UserAccess from "./UserAccess";
import Company from "./Company";

class User {
    constructor(data) {
        this.id = data.id;
        this.userName = data.userName;
        this.access = new UserAccess(data.access);
        this.email = data.email;
        this.first = data.first;
        this.idpId = data.idpId;
        this.isActive = data.isActive;
        this.last = data.last;
        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 5);
        this.experationDate = currentDate.toDateString();
        this.companies = (data.companies || []).map(company => new Company(company));
    }
}

export default User;