import config from '@/config/config.js'
import User from '@/Client/modules/pbadmin/models/User.js'
import axios from '@/config/axiosConfig'


export function Login(username, password) {
   const apiUrl = `${config.apiEndpoint}/login`;
   return axios.post(`${apiUrl}?userName=${username}&password=${password}`)
   .then(response => new User(response.data))
   .catch(error => Promise.reject(error));
}

//throw away function as a place holder until we get aws cognito working
export function getFakeLogin(username, password) {
   const apiUrl = `${config.apiEndpoint}/gentoken`;
   return axios.post(`${apiUrl}?userName=${username}&password=${password}`)
      .then(response => {

         const regex = /^JWT=([^;]+);/; // Regular expression to match JWT token
         const match = response.data.match(regex);

         if (match) {
            const token = match[1];
            localStorage.setItem("token", token); // Store the token in local storage
            
         }
      })
      .catch(error => Promise.reject(error));
}
export function saveProfile(user) {
   const apiUrl = `${config.apiEndpoint}/user/${user.id}`;
   return axios.post(`${apiUrl}`, user)
   .then(response => new User(response.data))
   .catch(error => Promise.reject(error));
}
export function saveSession(session) {
   const apiUrl = `${config.apiEndpoint}/session`;
   return axios.post(`${apiUrl}`, session)
   .then(response => new User(response.data))
   .catch(error => Promise.reject(error));
}