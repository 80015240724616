

import axios from '@/config/axiosConfig'
import config from '@/config/config'
import DialogParameter from '@/Client/modules/pbadmin/models/DialogParameter'
import Session from '@/Client/modules/pbadmin/models/Session'
import WorkflowStep from '@/Client/modules/pbadmin/models/WorkflowStep'
import store from '@/store'; 
import * as Sentry from '@sentry/browser'; 
 
 
  export async function getDialogByCode(code) {
    const transaction = Sentry.startTransaction({
      op: 'api-getDialogByCOde',
      name: 'Get Dialog by code',
    });
  
 
    return new Promise((resolve, reject) => {
      axios
      .get(`${config.apiEndpoint}/dialog/${code}`)
        .then((response) => {
          transaction.finish();
          resolve(new DialogParameter(response.data));
        })
        .catch((error) => {
          Sentry.captureException(error);
          transaction.finish();
          reject(error);
        });
    });
  }
  export async function getDialogList() {
    const transaction = Sentry.startTransaction({
      op: 'api-getDialogList',
      name: 'Get Dialog List',
    });
  const session = getCurrentSession();
 
    return new Promise((resolve, reject) => {
      axios
      .get(`${config.apiEndpoint}/dialog`)
        .then((response) => {
          resolve(response.data.map((dialog) => new DialogParameter(dialog)));
          transaction.finish();
        })
        .catch((error) => {
          Sentry.captureException(error);
          transaction.finish();
          reject(error);
        });
    });
  }
 
  
   
function getCompanyId(){
    if (!store.state.nonPersisted.company && store.state.persisted.user){
        store.state.nonPersisted.company = store.state.persisted.user.companies[0];
    }
    return store.state.nonPersisted.company.id;
}
function getCurrentSession(){
  return store.state.persisted.session;
}