
import Card from './Card';
import StepAction from './StepAction';
class StepActionListWithChildren {
    constructor(data) {
        if (data) {
            this.parentStepActions =  Array.isArray(data.parentStepActions) ? data.parentStepActions.map(stepAction => StepAction.create(stepAction)) : [];
            this.childStepActions = Array.isArray(data.childStepActions) ? data.childStepActions.map(stepAction => StepAction.create(stepAction)) : [];
           
        }  else {
            this.parentStepActions = [];
            this.childStepActions = [];
        }
    }
    static create(data) {
        return new StepActionListWithChildren(data);
    }
    filterChildrenByText(parentStepActionText) {
        if (!parentStepActionText) {
            return this.childStepActions;
        }
        const matchingParentStepIds = this.parentStepActions
            .filter(parentStepAction => parentStepAction.action && parentStepAction.action.actionSummary.includes(parentStepActionText))
            .map(filteredParentStepAction => filteredParentStepAction.toStepId);
        return this.childStepActions.filter(childStepAction => matchingParentStepIds.includes(childStepAction.stepId));
    }
    filterChildrenByParent(parentStepAction) {
        if (!parentStepAction) {
            return this.childStepActions;
        }
       
            return this.childStepActions.filter(childStepAction => childStepAction.stepId == parentStepAction.toStepId);
         
    }
    getParentChildList(){
        var data =  this.parentStepActions.map(parentStepAction => {
          
            return {
                key: parentStepAction.id,
                label: parentStepAction.action.actionSummary,
                toStepId: parentStepAction.toStepId,
                selectable:false,
                items: this.filterChildrenByParent(parentStepAction,'').map(childStepAction => {
                    return {
                        label: childStepAction.action.actionSummary,
                        value: childStepAction,
                        parent: parentStepAction,
                    };
                })
            
        };
        });
        return data;
    }
    

}

export default StepActionListWithChildren;


