 
<template #container="{ closeCallback }">
<span class="control-label">Replaced or Repaired Component</span>
    <div class="list-box-container">
         <AutoComplete v-if="treeNodes" v-model="currentStepAction.searchFilter" dropdown
            :suggestions="filteredList" @complete="search" optionLabel="label" optionGroupLabel="label"
            :style="{width: '100%', height: '100%'}"
            optionGroupChildren="items" v-on:item-select="loadStepInfo" v-focus="focus"     :virtualScrollerOptions="{ itemSize: 38 }">
            <template #optiongroup="slotProps">
                <div class="flex align-items-left">
                    <div>{{ slotProps.item.label }}</div>
                </div>
            </template>
   
        </AutoComplete>
        <Skeleton v-if="!treeNodes" width="10rem" height="4rem"></Skeleton>

    </div>
    <div v-if="currentStepAction.searchFilter.value" v-for="(dialogParameter, index) in currentStepAction.searchFilter.value.supplementalDataDefinitions"
        :key="index" class="supplemental-container">
        <supplementalDataDialogComponent :dialogParameter="dialogParameter" :currentStepAction="currentStepAction.searchFilter.value" />
    </div>
</template>
 

<script>
import { getStepActionsWithChildren } from '@/Client/modules/pbadmin/services/WorkflowStepService';
import { FilterMatchMode, FilterService } from 'primevue/api';
import supplementalDataDialogComponent from '@/newComponents/supplementalDataComponents/supplementalDataDialogComponent.vue';
import EventBus from '@/Client/modules/pbadmin/event_bus/EventBus.js'

export default {
    components: {
        supplementalDataDialogComponent
    },

    props: {
        currentStepAction: {
            type: Object,
            required: true
        },
        focus: {
            type: Boolean,
            default: false
        }

    },
    data() {
        return {
            selectedSystem: null,
            filteredList: null,
            selectedComponent: null,
            treeNodes: null,
            stepActionList: null,
            componentList: [],
            visible: true,

        };
    },
    //need to add mounted event to take the stepid parm and call the api.  The api call will take in the
    //stepid and return all step_actions for that step and all child step actions for each of those steps
    //list one is from the toStepId, list two would be the child items from list 1.  When an item is selected in list 2 that will dictate
    //where the next workflow step would be.   This control will have the potential to also have a supplemental data dialog or a position dialog.
    mounted() {
        this.init();
        console.log(this.focus );
    },

    computed: {


    },

    watch: {
        watch: {
            'currentStepAction.searchFilter': {
                immediate: true,
                handler(newVal) {
        
                    this.selectedComponent=newVal.value;
                }
            },
        },
    },

    methods: {
        init() {
            if (this.currentStepAction) {
                getStepActionsWithChildren(this.currentStepAction.toStepId).then((stepActions) => {
                    this.stepActionList = stepActions;
                    this.treeNodes = this.stepActionList.getParentChildList();
                });

            }
        },
        triggerLoadStepInfo(searchFilterValue) {

            let foundItem = null;
            for (let system of this.treeNodes) {
                foundItem = system.items.find(item => item.label === searchFilterValue);
                if (foundItem) break;
            }

            if (foundItem) {
                this.loadStepInfo({ value: foundItem });
            }
        },
        search(event) {
            let query = event.query;
            let filteredSystems = [];

            for (let system of this.treeNodes) {
                let filteredItems = FilterService.filter(system.items, ['label'], query, FilterMatchMode.CONTAINS);

                if (filteredItems && filteredItems.length) {
                    filteredSystems.push({ ...system, ...{ items: filteredItems } });
                }
            }

            this.filteredList = filteredSystems;
        },
        loadStepInfo(item) {
            var selected = item.value.value;
            this.selectedComponent = selected;
            this.selectedSystem = item.value.parent;
            EventBus.emit('cache-selection', { selectedComponent: this.selectedComponent, selectedSystem: this.selectedSystem });
          
            //EventBus.emit('reset-history', this.currentStepAction);
        },
    },
};
</script>
<style scoped>
.list-box-container {
    display: flex;
    gap: 20px;
    justify-content: left;
    padding: 10px;
    max-width: 50dvh;
    max-height: 20dvh;
    overflow-y: auto;
}

.p-input-icon-left {

    max-width: 500px !important;
}

.control-label {
  display: inline-block;
  margin-left: .1em;
  line-height: 2;
  align-items: center;

} 
</style>
