import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/HomeView.vue';
import About from '@/views/AboutView.vue';
import VehicleRepairOderInfo from '@/views/VehicleRepairOderView.vue'
import Workflow from '@/newViews/WorkflowView.vue'
 

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
 

  {
    path: '/RO',
    name: 'RepairOrder',
    component: VehicleRepairOderInfo,
  },
 
  {
    path: '/wf',
    name: 'workflow',
    component: Workflow,
    
  }
 
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
