import DialogParameterRow from "./DialogParameterRow";
import { v4 as uuidv4 } from 'uuid';


class DialogParameter {
    constructor(data) {
        this.id = data.id;
        this.tag = data.suppDataCode;
        this.supplementalDataRowDefinitions = Array.isArray(data.supplementalDataRowDefinitions) ? data.supplementalDataRowDefinitions.map(parameter => DialogParameterRow.create(parameter)) : [];
        this.suppDataMaxRows  =data.suppDataMaxRows;
        this.suppDataMinRows  =data.suppDataMinRows;
        this.suppDataDescription = data.suppDataDescription;
    }
    getDialogRows() {
         var rows = this.supplementalDataRowDefinitions 
         if (rows.length < this.suppDataMinRows){
            for (let i = rows.length; i < this.suppDataMinRows; i++) {
                this.addDialogRow(uuidv4());
            }
        }
       
        return this.supplementalDataRowDefinitions
    }
    addDialogRow(newId) {
        if (this.supplementalDataRowDefinitions.length > 0) {
            let lastRow = this.supplementalDataRowDefinitions[this.supplementalDataRowDefinitions.length - 1];
            var newRow =lastRow.clone(newId);
            this.supplementalDataRowDefinitions = [...this.supplementalDataRowDefinitions, newRow];
        }
    }
    getMaxNumberOfRows() {
        if (this.suppDataMaxRows) {
            return this.suppDataMaxRows;
        }
        return 0;
    }
    getMinNumberOfRows() {
        if (this.suppDataMinRows) {
            return this.suppDataMinRows;
        }
        return 0;
    }
    remvoeDialogRow(index) 
    {
        this.supplementalDataRowDefinitions.splice(index, 1);
    }
    canAddRows(rowIndex){
        if (this.suppDataMaxRows === this.suppDataMinRows){
            return false;
        } 
       if(rowIndex >= this.suppDataMaxRows){
                return false;
        }else{
            return true;
        }
         
    }
    canRemoveRows(rowIndex){
        if (this.supplementalDataRowDefinitions && this.supplementalDataRowDefinitions.length > 1){
            if (this.suppDataMaxRows === this.suppDataMinRows){
                return false;
            } 
            if (rowIndex < this.suppDataMinRows){
                return false
            }
            return true;
        }
        return false;
    }
    getColumnLabels(){
        if (this.supplementalDataRowDefinitions && this.supplementalDataRowDefinitions.length > 0){
            var cols =  this.supplementalDataRowDefinitions[0].suppDataColumns.map(row=> row.getColumnLabel() );
            return cols;
        }
        return [];
    }
    getOutputText(stepCategory) {
        var dialogOutputs = new Map();
    
        this.supplementalDataRowDefinitions.forEach(row => {
            var rowData = row.getOutputText(stepCategory);
            var rowOutputText = rowData.output;
    
            rowData.columnOutputs.forEach(columnOutput => {
                var output = columnOutput.output;
                var outputCategory = columnOutput.outputCategory;
    
                if (!output) {
                    return;
                }
                var rowText=rowOutputText && rowOutputText.length > 0 ? rowOutputText + " " + output : output
                if (dialogOutputs.has(outputCategory)) {
                    dialogOutputs.set(outputCategory, dialogOutputs.get(outputCategory) + "," + rowText);
                } else {
                    dialogOutputs.set(outputCategory, rowText);
                }
            });
        });
    
        return dialogOutputs;
    }
    isSearchDialog() {
        return this.tag === 'stepFilter';
    }  
}

export default DialogParameter;