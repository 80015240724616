<template>
  <Panel header="Vehicle Information">
    <div class="p-field">
      <label for="vin">VIN</label>
      <InputText v-model="vehicleInfo.vin" id="vin" />
    </div>
    <div class="p-field">

      <label for="make">Make</label>

      <Dropdown id="make" v-model="vehicleInfo.make" :options="makes" filter optionLabel="make" placeholder="Select a Make"
        class="w-full md:w-14rem">
        <template #value="slotProps">
          <div v-if="slotProps.value" class="flex align-items-center">
            <div>{{ slotProps.value.make }}</div>
          </div>
          <span v-else>
            {{ slotProps.placeholder }}
          </span>
        </template>
        <template #option="slotProps">
          <div class="flex align-items-center">
            <div>{{ slotProps.option.make }}</div>
          </div>
        </template>
      </Dropdown>
    </div>
    <div class="p-field">
      <label for="model">Model</label>
      <Dropdown id="model" v-model="vehicleInfo.model" :options="models" filter optionLabel="mode" placeholder="Select a Model"
        class="w-full md:w-14rem">
        <template #value="slotProps">
          <div v-if="slotProps.value" class="flex align-items-center">
            <div>{{ slotProps.value.model }}</div>
          </div>
          <span v-else>
            {{ slotProps.placeholder }}
          </span>
        </template>
        <template #option="slotProps">
          <div class="flex align-items-center">
            <div>{{ slotProps.option.model }}</div>
          </div>
        </template>
      </Dropdown>
    </div>
    <div class="p-field">
      <label for="year">Year</label>
      <InputText v-model="vehicleInfo.year" id="year" />
    </div>
    <div class="p-field">
      <label for="mileage">Mileage</label>
      <InputNumber  v-model="vehicleInfo.mileage" id="mileage"  :min="0" :max="9999999999"/>
    </div>
  </Panel>
</template>
<script>
import { getMFGInfo } from '@/Client/modules/pbadmin/services/VehicleService'
import Vehicle from '@/Client/modules/pbadmin/models/Vehicle' 

export default {
  props: {
    vehicleInfo: {
      type: Vehicle,
      required: true
    } 
  },
  data() {
    return {
      makes: [],
      models: [],
    };
  },
  created() {
    this.getMakes();
  },
  watch: {
    'vehicleInfo.make'(newMake) {
    this.vehicleInfo.model="";
    if (newMake) {
      this.models=newMake.models
    } else {
      this.models= []
    }
  }
  },
  methods: {
    getMakes() {
      this.makes = getMFGInfo()
    }
  },
};
</script>
  