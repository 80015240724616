class StepChain {
    constructor(data) {
        this.id = data.id;
        this.stepName = data.stepName;
        this.stepText = data.stepText;
        this.stepType = data.stepType;
        this.companyId = data.companyId;
        this.level = data.level;
        this.path = data.path;
    }
    static create(data) {
        return new StepChain(data);
     
    }
}
export default StepChain;