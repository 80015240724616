import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

const state = {
    persisted: {
      user: null,
      session:null,
    },
    nonPersisted: {
      navItems: [],
      company:null,
      
    }
};

const mutations = {
    SET_USER(state, user) {
      state.persisted.user = user;
    },
    SET_SESSION(state, session) {
      state.persisted.session = session;
    },
    ADD_NAV_ITEM(state, item) {
      state.nonPersisted.navItems.push(...item);
    },
    CLEAR_NAV_ITEMS(state) {
      state.nonPersisted.navItems = [];
    },
    SET_COMPANY(state, item){
      state.nonPersisted.company = item;
    },
};

const actions = {
    getUser({ state }) {
      return state.persisted.user;
    },
    setUser({ commit }, user) {
      commit('SET_USER', user);
    },
    getCurrentSession({ state }) {
      return state.persisted.session;
    },
    setSession({ commit }, session) {
      commit('SET_SESSION', session);
    },
    setCompany({ commit }, company) {
      commit('SET_COMPANY', company);
    },
 
    getCompany({ state }) {
      state.nonPersisted.company;
    },
    addNavItem({ commit }, item) {
      commit('ADD_NAV_ITEM', item);
    },
    clearNavItems({ commit }) {
      commit('CLEAR_NAV_ITEMS');
    },
    logout({ commit }) {
      commit('SET_USER', null);
      commit('SET_SESSION',null);
      commit('CLEAR_NAV_ITEMS');
    }
};

const plugins = [
    createPersistedState({
      paths: ['persisted']
    })
];

const store = createStore({
  state,
  mutations,
  actions,
  plugins
});

export default store;
