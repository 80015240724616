 <template>
    <div class="container">
        <workflowNavigation class="full-width" />
      <!--  <SelectButton :options="options" v-model="selectedOption" @change="toggleEditor"></SelectButton>-->
        <div class="grid-container">
            <priorStepButtonBarComponent class="col-narrow" v-if="showToolBar" :step="workflowManager.currentStep" :startingStepId="startingStepId"
                :goToNextStep="nextStep" :goToSummary="goToSummary" :goToPriorStep="priorStep"
                :onSummaryScreen="onSummaryScreen" >
            </priorStepButtonBarComponent>

            <div class="step-content col-main">
                <div class="spacer step-title">{{ stepTitle }}</div>
                <workflowStepEditor v-if="this.onEditScreen" :step="workflowManager.currentStep" />
                <workflowStep v-if="showStepUI" @selectionChanged="selectAction" :workflowManager="workflowManager"></workflowStep>
                <stepSummary v-if="onSummaryScreen" :workflowManager="workflowManager" :onSummaryScreen="onSummaryScreen"></stepSummary>
            </div>

            <nextStepButtonBarComponent class="col-narrow" v-if="showToolBar" :step="workflowManager.currentStep" :startingStepId="startingStepId"
                :goToNextStep="nextStep" :goToSummary="goToSummary" :goToPriorStep="priorStep"
                :onSummaryScreen="onSummaryScreen" :onlyButtons="true" >
            </nextStepButtonBarComponent>

            <selectedActionsTree class="col-remaining" :selectedSteps="selectedStepsData" :onIndexs="indexes" :ref="scrollContainer"></selectedActionsTree>
        </div>
    </div>
</template>

<script>
 
import EventBus from '@/Client/modules/pbadmin/event_bus/EventBus.js'
import priorStepButtonBarComponent from '@/newComponents/stepComponents/priorStepButtonBarComponent.vue'
import nextStepButtonBarComponent from '@/newComponents/stepComponents/nextStepButtonBarComponent.vue'
import stepSummary from '@/newComponents/stepComponents/stepSummaryComponent.vue'
import workflowNavigation from '@/newComponents/workflowNavigation.vue'
import workflowStep from '@/newComponents/stepComponents/stepComponent.vue'
import workflowStepEditor from '@/newComponents/stepEditorComponents/stepEditorComponent.vue'
import selectedActionsTree from '@/newComponents/stepComponents/stepSelectedActionComponent.vue'
import { mapActions, mapState } from 'vuex'
import WorkflowManager from '@/Client/modules/pbadmin/manager/WorkflowManager.js'
import { watch } from 'vue';

export default {
    name: 'Workflow',
    components: {
        workflowNavigation,
        workflowStep,
        priorStepButtonBarComponent,
        nextStepButtonBarComponent,
        selectedActionsTree,
        stepSummary,
        workflowStepEditor
    },
    props: { workflowId: { type: String, required: true } },
    data() {
        return {
            workflow: null,
            step: null,
            priorSteps: [],
            indexes: [],
            onSummaryScreen: false,
            selectedActionChanged: false,
            selectedStepsData: [],
            onEditScreen: false,
            workflowManager:  null,
            options: ['Edit', 'Preview'],
        }
    },
    beforeMount() {
        //this.workflowManager = WorkflowManager.create(this.workflowId);
        //this.selectedStepsData = this.workflowManager.selectedActionsForSteps;
        watch(
            () => this.getworkflowManager.selectedActionsForSteps,
            (newVal) => {
                this.selectedStepsData = [...newVal];
                this.autoScrollToBottom();
            }
        );
    },

    mounted() {
        this.getUser();
        this.initWorkFlow();  
        EventBus.on('breadcrumb-selected', this.changeStepFromBreadcrumb);
        EventBus.on('edit-step', this.IsEditingStep);
        EventBus.on('cache-selection', this.cacheSelections);
        EventBus.on('summary-breadcrumb-selected', this.onSummary); 
        EventBus.on('user-logged-out', this.signedOff);
       EventBus.on('reset-history',this.resetHistory);

    },
    beforeUnmount() {
        EventBus.off('breadcrumb-selected', this.changeStepFromBreadcrumb);
        EventBus.off('edit-step', this.IsEditingStep);
        EventBus.off('summary-breadcrumb-selected', this.onSummary);
        EventBus.off('user-logged-out', this.signedOff);
        EventBus.off('cache-selection', this.cacheSelections);
    },

    computed: {
        ...mapState({ user: state => state.persisted.user, session: state => state.persisted.session }),
        showToolBar() { return this.onEditScreen || (this.getworkflowManager.currentStep && !this.onSummaryScreen); },
        showStepUI() { return !this.onEditScreen && (this.getworkflowManager.currentStep && !this.onSummaryScreen); },
        startingStepId() { return  this.getworkflowManager.currentStep ? this.getworkflowManager.getStartingStepId() : null; },
        getworkflowManager(){
            if(!this.workflowManager){
                this.workflowManager = WorkflowManager.create(this.workflowId);
                return this.workflowManager;
            }
            return this.workflowManager;

        },
        stepTitle(){
            if (this.onSummaryScreen) return "Summary";
            if(this.getworkflowManager){
                return this.getworkflowManager.CurrentStepTitle();
            }
            return "";
        },
        
    },
    methods: {
        async initWorkFlow(){
           await this.getworkflowManager.getWorkflow().then(workflow => {
                    this.loadStep();
                    this.selectedStepsData = this.getworkflowManager.selectedActionsForSteps;
                    watch(() => this.workflowManager.selectedActionsForSteps,(newVal) => {
                                this.selectedStepsData = [...newVal];
                    });
            })
        },
        ...mapActions(['setUser', 'getUser', 'addNavItem', 'setCompany']),
        async loadStep(stepId) {
          
            await this.getworkflowManager.getNextStep(stepId).then(step => {
                if (step){
                    return this.setStepLoaded(step);
                }
            });
        },
        selectAction() { },
        setStepObject(selectedStep) {
             
            this.getworkflowManager.currentStep = selectedStep;
            EventBus.emit('current-step-changed', this.getworkflowManager.currentStep);
        },
        setStepLoaded(step) {
           
         if (step) {
            this.setPriorStep(this.workflowManager.currentStep);
            this.setStepObject(step);
            this.setBreadcrumb(step);
        }
        },
        setPriorStep(step) {
            const index = this.priorSteps.findIndex(priorStep => priorStep.id === step.id);
            if (index > -1) this.priorSteps[index] = step;
            else this.priorSteps.push(step);
        },
        setBreadcrumb(step) { EventBus.emit('add-breadcrumb', { label: `${step.stepText}`, step: step }); },
        setFinishedBreadcrumb(title) { EventBus.emit('add-breadcrumb', { label: `${title}` }); },
        nextStep(stepId) {
            this.updateStepHistory();
            this.loadStep(stepId);
        },
        cacheSelections(selectedItems) {
            this.getworkflowManager.addToCache(selectedItems.selectedSystem, "system")
            this.getworkflowManager.addToCache(selectedItems.selectedComponent,"component")
           
        },
        priorStep() {
            const step = this.priorSteps.find(step => step.id == this.getworkflowManager.currentStep.fromStep);
            if (step) {
                this.setStepObject(step);
                this.onSummaryScreen = false;
            }
        },
        changeStepFromBreadcrumb(step) {
            this.setStepObject(step);
            this.updateStepHistory();
            this.onSummaryScreen = false;
        },
        async goToSummary() {
            this.setPriorStep(this.getworkflowManager.currentStep);
            this.updateStepHistory().then(() => {
                this.onSummaryScreen = true;
                this.setFinishedBreadcrumb("Summary");
            });
        },
        resetHistory(stepAction){
           
            this.workflowManager.resetHistory(stepAction);
            EventBus.emit('current-step-changed', this.getworkflowManager.currentStep);
            EventBus.emit('reset-to-breadcrumb', this.getworkflowManager.currentStep);
        },
        onSummary(){
             
            EventBus.emit('current-step-changed', null);
            this.onSummaryScreen = true;
        },
      
        async updateStepHistory() {
            if (this.getworkflowManager.currentStep) {
              await  this.getworkflowManager.updateStepHistory();
                this.session.addStepToSession(this.getworkflowManager.currentStep);
            }
        },
        signedOff(){
            this.workflowId = null;
            this.workflowManager = null;
        },
        IsEditingStep(onEdit) { this.onEditScreen = onEdit; },
        autoScrollToBottom() {
           console.log(this.$refs.scrollContainer);
                if (this.$refs.scrollContainer) {
                    const scroll = this.$refs.scrollContainer;
                    scroll.scrollTop = scroll.scrollHeight;
                }
           
        },
        toggleEditor(){
          
          if (this.selectedOption == 'Edit')
          {
            this.IsEditingStep(true);
          }
          else
          {
            this.IsEditingStep(false);
          }
      }
    }
};
</script>

 <style scoped>
 .spacer.step-title {
  text-align: center;
  font-size: 30px;
  margin-bottom: 25px;
  font-weight: bold;
  column-span: all;
}
.container {
    width: 99%;
    min-height: 50%;
    height: 100%; 
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.full-width {
    grid-column: 1 / -1;
}

.grid-container {
    display: grid;
    grid-template-columns: min-content 50% min-content auto; 
    grid-gap: 0px; 
    flex: 1; 
    margin-top: 10px;
    max-height: 100%;  
    
}
.col-main {
    
    margin-top: 10px;
    margin-left: 15px;
    margin-right:15px;
    align-items: center;
}

.col-remaining {
    border: 1px solid #d1d5db;
    max-width: 100%; 
    overflow-y: auto;
    max-height: 72dvh;
}
.main-content, .side-panel {
    display: none;
}
.col-narrow{
    margin-top: 75px;
}
</style>
