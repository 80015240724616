

import axios from '@/config/axiosConfig'
import config from '@/config/config'
import StepChain from '@/Client/modules/pbadmin/models/StepChain'
import Session from '@/Client/modules/pbadmin/models/Session'
import WorkflowStep from '@/Client/modules/pbadmin/models/WorkflowStep'
import store from '@/store'; 
import * as Sentry from '@sentry/browser'; 
 import StepActionListWithChildren from '@/Client/modules/pbadmin/models/StepActionListWithChildren';
 
  export async function getStep(stepid) {
    const transaction = Sentry.startTransaction({
      op: 'api-getStep',
      name: 'Get Step',
    });
  const session = getCurrentSession();
   
    return new Promise((resolve, reject) => {
      axios
      .post(`${config.apiEndpoint}/step/${stepid}`, session)
        .then((response) => {
          transaction.finish();
          resolve(new WorkflowStep(response.data));
        })
        .catch((error) => {
          Sentry.captureException(error);
          transaction.finish();
          reject(error);
        });
    });
  }
  export async function getToStepsForEdit(fromStepid) {
    const transaction = Sentry.startTransaction({
      op: 'api-getStep',
      name: 'Get Step',
    });
  const session = getCurrentSession();
 
    return new Promise((resolve, reject) => {
      axios
      .post(`${config.apiEndpoint}/step/${fromStepid}/toSteps`, session)
        .then((response) => {
          transaction.finish();
          resolve(response.data.map((stepChain) => new StepChain(stepChain)));
        })
        .catch((error) => {
          Sentry.captureException(error);
          transaction.finish();
          reject(error);
        });
    });
  }
  export async function getStepActionsWithChildren(Stepid) {
    const transaction = Sentry.startTransaction({
      op: 'api-getStepActionsWithChildren',
      name: 'Get getActionsWithChildren',
    });
  const session = getCurrentSession();
 
    return new Promise((resolve, reject) => {
      axios
      .get(`${config.apiEndpoint}/step/${Stepid}/actions`, session)
        .then((response) => {
          transaction.finish();
  
          resolve(new StepActionListWithChildren(response.data));
        })
        .catch((error) => {
          Sentry.captureException(error);
          transaction.finish();
          reject(error);
          console.log(error);
        });
    });
  }
function getCompanyId(){
    if (!store.state.nonPersisted.company && store.state.persisted.user){
        store.state.nonPersisted.company = store.state.persisted.user.companies[0];
    }
    return store.state.nonPersisted.company.id;
}
function getCurrentSession(){
  return store.state.persisted.session;
}