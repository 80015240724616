
class Card {
    constructor(data) {
        this.id = data.id;
        this.description = data.description;
        this.title = data.name;
        this.isCompany = data.isCompany;
        this.transitions = data.transitions
    }
}

export default Card;