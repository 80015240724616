class UserAccess {
    constructor(data) {
        this.canEditActions = data.canEditActions;
        this.canEditComponentCategory= data.canEditComponentCategory;
        this.canEditComponents= data.canEditComponents;
        this.canEditROData= data.canEditROData;
        this.canEditSystem= data.canEditSystem;
        this.canEditSystemType= data.canEditSystemType;
        this.canEditWorkflow= data.canEditWorkflow;
        this.canEditWorkflowSteps= data.canEditWorkflowSteps;
    }
}

export default UserAccess;