class DialogColumnOutput {
    constructor(data) {
        this.output = data.output;
        this.outputCategory = data.outputCategory;
        
    }
    static create(data) {
        return new DialogColumnOutput(data);
    }
}

export default DialogColumnOutput;