<template>
  <LoginComponent v-if="!isUserSet" @logged-in="setUserAfterLogin" />
  <div v-else>
    <!-- toolbar -->
    <div class="min-h-screen flex flex-column surface-ground">
      <toolbar @loadWorkflow="startWorkflow"></toolbar>
      <div class="p-1 flex flex-column flex-auto">
        <div class="border-2 border-dashed surface-border border-round surface-section flex-auto">
          <WorkflowView v-if="workflowId" :workflowId="workflowId"  :key="sessionKey" ></WorkflowView>
        </div>
      </div>
    </div>
    <Toast position="bottom-right" group="br" />
  </div>
</template>

<style>
@media (min-width: 768px) {
  .p-grid.p-justify-between {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    justify-content: flex-start;
  }
}
</style>

<script>
import toolbar from '@/newComponents/toolbar.vue'
import userProfile from '@/newComponents/userProfile.vue'
import LoginComponent from './components/LoginComponent.vue'
import WorkflowView from './newViews/WorkflowView.vue'
import { mapState, mapActions } from 'vuex'
import Session from '@/Client/modules/pbadmin/models/Session';
import config from './config/config'
import { usePrimeVue } from 'primevue/config';
import { v4 as uuidv4 } from 'uuid';
import EventBus from '@/Client/modules/pbadmin/event_bus/EventBus.js'

export default {
  components: {
    toolbar,
    LoginComponent,
    userProfile,
    WorkflowView
  },
  data() {
    return {
      vehicleInfo: '',
      socket: null,
      isSlideOutOpen: false,
      doWorkflow: false,
      workflowId: null,
      sessionToSave: null,
      sessionKey:uuidv4().toString(),
    }
  },
  mounted() {
    EventBus.on('user-logged-out', this.signedOff);
  },

 
  beforeUnmount() {
    if (this.socket) {
      this.socket.close();
    }
    EventBus.off('user-logged-out', this.signedOff);
  },
  computed: {
    ...mapState({
      user: state => state.persisted.user
    }),
    isUserSet() {
       if (!this.user){
        return false;
       }
      if (this.user.experationDate) {
        var now = new Date();
        var exp = new Date(this.user.experationDate);
        if (now > exp) {
          return false;
        }else{
          return true;
        }
      }else
      {
        return false;
      }
    },

  },
  methods: {
    ...mapActions(['setUser', 'logout', 'addNavItem', 'setCompany', 'getCompany', 'setSession', 'getCurrentSession']),
     

    setUserAfterLogin(usr) {
      this.setUser(usr);
      this.setupWebSocket(usr);
    },
    signedOff() {
      this.logout();
      this.sessionToSave = null;
      this.sessionKey =null;
      this.user = null;
      this.workflowId = null;
      if (this.socket) {
        this.socket.close();
      }
    },
    setBreadcrumb(menuItem) {
      this.addNavItem(
        [{ label: `${menuItem}` },]
      )
      
    },
    startWorkflow(workflowId, menuItem) {
      this.sessionKey = uuidv4().toString();
      if (this.handleSession(workflowId)) {
        this.workflowId = workflowId;
        this.setBreadcrumb(menuItem);
      }
    },
    async handleSession(workflowId) {
      const currentSession = this.getCurrentSession();
      if (currentSession) {
        this.sessionToSave = currentSession;
        // return false;
      }
      const company =this.user.companies[0];
      this.setSession(Session.newSession({ id: uuidv4(), workflowId: workflowId, userId: this.user.id, companyId: company.id }));
      return true;
    },
    setupWebSocket(usr) {
      this.socket = new WebSocket(`${config.webSocketEndpoint}?token=${usr.userName}`);

      this.socket.onopen = event => {

        const registrationMessage = {
          type: 'register',
          token: usr.userName
        };
        this.socket.send(JSON.stringify(registrationMessage));
        setInterval(() => {
          this.socket.send("heartbeat");
        }, 30000);
      };

      this.socket.onclose = event => {

      };

      this.socket.onerror = error => {
        console.error("WebSocket error: ", error);
      };

      this.socket.onmessage = message => {

        this.vehicleInfo = message.data;
      };
    }
  }
}
</script>

 
