<template>
    <div class="card flex justify-content-left">
        <Breadcrumb :home="home" :model="breadcrumbs">
            <template #item="{ item }">
                <a :class="{ 'highlighted-breadcrumb': isCurrentStep(item.step) }">
                    <span v-if="item.icon" :class="item.icon" @click="goHome" />
                    <span v-else @click="handleBreadcrumbClick(item)" 
                        >
                        {{ item.label }}
                    </span>
                </a>
            </template>
        </Breadcrumb>
    </div>
</template>

<script>
import EventBus from '@/Client/modules/pbadmin/event_bus/EventBus.js'
export default {
    name: 'workflowNavigation',
    data() {
        return {
            breadcrumbs: [],
            currentStep: null
        };
    },
    mounted() {
        EventBus.on('add-breadcrumb', this.addBreadcrumb);
        EventBus.on('remove-breadcrumb', this.removeBreadcrumb);
        EventBus.on('reset-to-breadcrumb', this.resetBreadcrumbs);
        EventBus.on('current-step-changed', this.setCurrentStep);
    },
    beforeUnmount() {
        EventBus.off('add-breadcrumb', this.addBreadcrumb);
        EventBus.off('remove-breadcrumb', this.removeBreadcrumb);
        EventBus.off('current-step-changed', this.setCurrentStep);
        EventBus.off('reset-to-breadcrumb', this.resetBreadcrumbs);
    },
    computed: {
        home() {
            return {
                icon: 'pi pi-home',
                label: 'Home',
                to: '/'
            }
        },
       
    },
    methods: {
        resetBreadcrumbs(step){
            var index = this.breadcrumbs.findIndex(crumb => crumb.step.id == step.Id);
            this.breadcrumbs.splice(index+1);
        },
        goHome() {
            this.$router.push('/');
        },
        addBreadcrumb(item) {
            if (!this.breadcrumbs.some(breadcrumb => breadcrumb.label === item.label)) {
                this.breadcrumbs.push(item);
            }
        },
        removeBreadcrumb(label) {
            const index = this.breadcrumbs.findIndex(bc => bc.label === label);
            if (index !== -1) {
                this.breadcrumbs.splice(index, 1);
            }
        },
        handleBreadcrumbClick(breadcrumbItem) {
            
            if (breadcrumbItem.label == "Summary"){
                EventBus.emit('summary-breadcrumb-selected');
                return;
            }
            if (breadcrumbItem.step.isSingleSelect()) {
                const index = this.breadcrumbs.findIndex(breadcrumb => breadcrumb.label === breadcrumbItem.step.stepText);

                if (index > -1) {
                    this.breadcrumbs.splice(index + 1);
                }
            }
            EventBus.emit('breadcrumb-selected', breadcrumbItem.step);
        },
        isCurrentStep(step) {
            if (!this.currentStep || !this.currentStep.stepText) {
                return false;
            }
            if (!step && !this.currentStep) {
                return true;
            }
            if (step && !this.currentStep) {
                return false;
            }
            if (!step && this.currentStep) {
                return false;
            }

            return step.stepText === this.currentStep.stepText;
        },
        setCurrentStep(step) {
            this.currentStep = step;
        }   
    }
};
</script>
<style scoped>
.highlighted-breadcrumb {
    font-style: italic;
   /* background-color:aliceblue;*/
}
</style>
