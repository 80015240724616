export function joinWithCommasAnd(arr, ignorePhrase) {
    if (arr.length === 0) return '';

    let commonPhrasesArray = findCommonPhrase(arr, ignorePhrase);
    commonPhrasesArray.sort((a, b) => a.outputOrder - b.outputOrder);
    console.log("Output Array: ", commonPhrasesArray);
    let joinedSentences = [];
    let currentCommonPhrase = '';
    let sentencesForCurrentPhrase = [];

    commonPhrasesArray.forEach(actionOutput => {
        if (actionOutput.commonPhrase !== currentCommonPhrase) {
            if (sentencesForCurrentPhrase.length > 0) {
                joinedSentences.push(constructSentence(currentCommonPhrase, sentencesForCurrentPhrase)+".");
            }
            currentCommonPhrase = actionOutput.commonPhrase;
            sentencesForCurrentPhrase = [];
        }
        sentencesForCurrentPhrase.push(actionOutput.actionOutput);
    });

    if (sentencesForCurrentPhrase.length > 0) {
        joinedSentences.push(constructSentence(currentCommonPhrase, sentencesForCurrentPhrase));
    }

    return joinedSentences.join(' ');
}

function constructSentence(commonPhrase, sentences) {
    let combinedSentence = commonPhrase === '' ?
        joinSentences(sentences) :
        `${capitalizeFirstLetter(commonPhrase)} ${joinSentences(sentences.map(s => s.replace(commonPhrase, '').trim()))}`;
    return combinedSentence;
}

function joinSentences(sentences) {
    if (sentences.length === 1) {
        return sentences[0];
    }
    //const lastSentence = `and ${sentences[sentences.length - 1].trim()}`;
    var lastSentence = `${sentences[sentences.length - 1].trim()}`;
    if (!lastSentence.includes(" and ")){
        lastSentence = `and ${lastSentence}`;
    }
    const otherSentences = sentences.slice(0, -1).map(sentence => sentence.trim() + ',');
    return [...otherSentences, lastSentence].join(' ');
}

export function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function findCommonPhrase(actionOutputArray, ignorePhrase) {
    if (!actionOutputArray || actionOutputArray.length === 0) return [];

    actionOutputArray.sort((a, b) => a.outputOrder - b.outputOrder);
    let commonPhrasesArray = [];

    for (let i = 0; i < actionOutputArray.length; i++) {
        if (!actionOutputArray[i].actionOutput){
            continue;
        }
        let currentActionOutput = actionOutputArray[i];
        let commonPhrase = '';
        let j = i + 1;

        while (j < actionOutputArray.length && (actionOutputArray[j].outputOrder === currentActionOutput.outputOrder || actionOutputArray[j].outputOrder === actionOutputArray[j - 1].outputOrder + 1)) {
            let tempCommonPhrase = commonStart(currentActionOutput.actionOutput, actionOutputArray[j].actionOutput);
            if (tempCommonPhrase && tempCommonPhrase !== ignorePhrase && tempCommonPhrase.length > commonPhrase.length) {
                commonPhrase = tempCommonPhrase;
            }
            j++;
        }

        if (commonPhrase) {
            currentActionOutput.commonPhrase = commonPhrase;
            for (let k = i + 1; k < j; k++) {
                if (commonStart(currentActionOutput.actionOutput, actionOutputArray[k].actionOutput) === commonPhrase) {
                    actionOutputArray[k].commonPhrase = commonPhrase;
                    actionOutputArray[k].actionOutput = actionOutputArray[k].actionOutput.replace(commonPhrase, '').trim();
                }
            }
        } else {
            currentActionOutput.commonPhrase = commonPhrase;
            currentActionOutput.actionOutput = currentActionOutput.actionOutput.replace(commonPhrase, '').trim();
        }

        commonPhrasesArray.push(currentActionOutput);
    }
 
    return commonPhrasesArray;
}

function commonStart(str1, str2) {
    const words1 = str1.split(/\s+/);
    const words2 = str2.split(/\s+/);
    let i = 0, commonWords = [];
    while (i < words1.length && i < words2.length && words1[i] === words2[i]) {
        commonWords.push(words1[i]);
        i++;
    }
    return commonWords.length >= 3 ? commonWords.join(' ').trim() : '';
}
export function extractSelectedValues(str) {
    const regex = /\{selected:([^\}]+)\}/g;
    let matches = [];
    let match;

    while ((match = regex.exec(str)) !== null) {
        matches.push(match[1]);
    }

    return matches;
}