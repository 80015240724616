<template>
    <div class="surface-overlay py-3 px-6 flex align-items-center justify-content-between relative lg:static"
        style="min-height: 70px">
        <img :src="companyLogo" alt="Image" height="40" class="mr-0 lg:mr-6">
        <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple"
            v-bind:style="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
            <i class="pi pi-bars text-4xl"></i>
        </a>
        <div
            class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none">
            <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">

                <li class="lg:relative">

                    <a v-for="company in user.companies" :key="company.id" @click="selectedCompanyId = company.id" v-ripple
                        class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                        v-bind:style="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
                        <i class="pi pi-wrench mr-2"></i>
                        <span>{{ company.companyDescription }}</span>
                        <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                    </a>

                </li>
                <li>
                    <a @click="startWorkflow('ef2657e1-577f-4609-a315-4c4993c19c71', 'New Story')"
                        class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <i class="pi pi-book mr-2"></i>
                        <span>New Story</span>
                    </a>
                </li>
                <li>
                    <a @click="startMaintWorkflow('8943c991-6fa5-468c-924b-0d683925be47', 'New Maintenance')"
                        class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <i class="pi pi-cog mr-2"></i>
                        <span>New Maintenance</span>
                    </a>
                </li>
                <li>
                    <a @click="toggleWIPSlideOut"
                        class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <i class="pi pi-briefcase mr-2"></i>
                        <span>Work In Progress</span>
                    </a>
                </li>
            </ul>
            <ul
                class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
                <li>
                    <a v-ripple @click="loggOut"
                        class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <i class="pi pi-sign-out text-base lg:text-2xl mr-2 lg:mr-0"></i>
                        <span class="block lg:hidden font-medium">Sign Off</span>
                    </a>
                </li>
                <li>
                    <a v-ripple @click="toggleDeviceSlideOut"
                        class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <i class="pi pi-tablet text-base lg:text-2xl mr-2 lg:mr-0"></i>
                        <span class="block lg:hidden font-medium">Devices</span>
                    </a>
                </li>
                <li class="border-top-1 surface-border lg:border-top-none">
                    <a @click="toggleUserSlideOut"
                        class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <i class="pi pi-user text-base lg:text-2xl mr-2 lg:mr-0"></i>
                    </a>
                </li>
            </ul>
            <transition name="slide-fade">
                <userProfile :isUserSlideOutOpen="isUserSlideOutOpen" @updateUserSlideOut="toggleUserSlideOut">
                </userProfile>
            </transition>
            <transition name="slide-fade">
                <deviceProfiles :isDeviceSlideOutOpen="isDeviceSlideOutOpen" @updateDeviceSlideOut="toggleDeviceSlideOut">
                </deviceProfiles>
            </transition>
            <transition name="slide-fade">
                <workInProgress :isWIPSlideOutOpen="isWIPSlideOutOpen" @updateWIPSlideOut="toggleWIPSlideOut">
                </workInProgress>
            </transition>
        </div>
    </div>
</template>
<script>


import User from '@/Client/modules/pbadmin/models/User.js'
import userProfile from './userProfile.vue'
import workInProgress from './workInProgress.vue'
import deviceProfiles from './deviceComponents/deviceProfiles.vue'
import { mapState, mapActions } from 'vuex'
import EventBus from '@/Client/modules/pbadmin/event_bus/EventBus.js'

export default {
    name: 'toolbar',
    components: {
        userProfile,
        deviceProfiles,
        workInProgress
    },
    data() {
        return {
            company: null,
            selectedCompanyId: null,
            isUserSlideOutOpen: false,
            isDeviceSlideOutOpen: false,
            isWIPSlideOutOpen: false,
            toggleMaintenanceContentArea: false,
        };
    },
    mounted() {
        this.SelectedCompany();

    },
    computed: {
        companyLogo() {
            const selctedCompany = this.SelectedCompany();
            if (selctedCompany) {
                return "/logos/" + selctedCompany.logoName;

            }
            return null;
        },
        ...mapState({
            user: state => state.persisted.user
        }),

    },
    methods: {
        SelectedCompany() {
            if (this.user) {
                this.company = this.user.companies.find(company => company.id === this.selectedCompanyId);
                if (!this.company) {
                    this.company = this.user.companies[0];
                }
                return this.company;
            }
            return null;
        },
        toggleUserSlideOut() {
            this.isUserSlideOutOpen = !this.isUserSlideOutOpen;
        },
        toggleDeviceSlideOut() {
            this.isDeviceSlideOutOpen = !this.isDeviceSlideOutOpen;
        },
        toggleWIPSlideOut() {
            this.isWIPSlideOutOpen = !this.isWIPSlideOutOpen;
        },
        startMaintWorkflow(workflowId, menuItem) {
            this.$emit('loadWorkflow', workflowId, menuItem);
        },
        startWorkflow(workflowId, menuItem) {
            //TODO: hard coded workflow id for wf2!! this should be loaded and tied to the menu item so on click we can pass that in and allow the same approach for maintenance as well
            this.$emit('loadWorkflow', workflowId, menuItem);
        },
        loggOut(){
        EventBus.emit('user-logged-out');

    },
    },
};
</script>
<style scoped></style>