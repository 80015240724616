<template>
  
  <div class="checkbox-grid">
    <div class="column">
      <div v-for="(stepAction, index) in firstColumnActions" :key="stepAction.id">
        <div class="grid-item">
          <div class="flex align-items-center">
            <RadioButton v-if="workflowManager.currentStep.isSingleSelect()" v-model="stepAction.selected" :inputId="stepAction.id"
              :name="stepAction.id" :value="true" @update:modelValue="onSelectedActionChange(stepAction)" v-focus="(index ===0)" />

            <Checkbox v-if="(!workflowManager.currentStep.isSingleSelect())" v-model="stepAction.selected" :binary=true :inputId="stepAction.id"
              :name="stepAction.id" :value="stepAction.selected" @update:modelValue="onSelectedActionChange(stepAction)" v-focus="(index ===0)" />
            <label :for="stepAction.id" class="form-radio-label">
              {{ stepAction.filteredQuestion(selectedComponent,selectedSystem)}}
            </label>
          </div>
        </div>
        <div v-if="stepAction.selected" v-for="(dialogParameter, index) in stepAction.supplementalDataDefinitions"
            :key="index" class="supplemental-container">
            <supplementalDataControlCustomSearch v-if="dialogParameter.isSearchDialog()" :currentStepAction="stepAction"  :focus="(index ==0)"  />
            <supplementalDataDialogComponent  v-if="!dialogParameter.isSearchDialog()" :dialogParameter="dialogParameter" :currentStepAction="stepAction"  :focus="(index ==0)"/>
        </div>
      </div>
    </div>
    <div class="column" v-if="secondColumnActions.length > 0">
      <div v-for="(stepAction, index) in secondColumnActions" :key="stepAction.id">
        <div class="grid-item left-align">
          <div class="flex align-items-center">
            <RadioButton v-if="workflowManager.currentStep.isSingleSelect()" v-model="stepAction.selected" :inputId="stepAction.id"
              :name="stepAction.id" :value="true" @update:modelValue="onSelectedActionChange(stepAction)" />

            <Checkbox v-if="(!workflowManager.currentStep.isSingleSelect())" v-model="stepAction.selected" :binary=true :inputId="stepAction.id"
              :name="stepAction.id" :value="stepAction.selected" @update:modelValue="onSelectedActionChange(stepAction)"/>
            <label :for="stepAction.id" class="form-radio-label">
              {{ stepAction.filteredQuestion(selectedComponent,selectedSystem) }}
            </label>
          </div>
        </div>
        <div v-if="stepAction.selected" v-for="(dialogParameter, index) in stepAction.supplementalDataDefinitions"
            :key="index" class="supplemental-container">
          <supplementalDataDialogComponent  v-if="!dialogParameter.isSearchDialog()" :dialogParameter="dialogParameter" :currentStepAction="stepAction" :v-focus="(index ==0)"/>
         <supplementalDataControlCustomSearch v-if="dialogParameter.isSearchDialog()" :currentStepAction="stepAction"  :v-focus="(index ==0)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/Client/modules/pbadmin/event_bus/EventBus.js'
import supplementalDataDialogComponent from '@/newComponents/supplementalDataComponents/supplementalDataDialogComponent.vue';
import supplementalDataControlCustomSearch from '@/newComponents/supplementalDataComponents/supplementalDataControlCustomSearch.vue';
import {consoleLogWithSentry} from '@/Client/modules/pbadmin/observability/logging';
import { mapActions, mapState } from 'vuex'
import WorkflowManager from '@/Client/modules/pbadmin/manager/WorkflowManager';

export default {
  name: 'workflowStep',
  components: {
    supplementalDataDialogComponent,
    supplementalDataControlCustomSearch
  },

  props: {
    workflowManager:{
      type: WorkflowManager,
      required: true,
    }
  },
  
  computed: {

    stepText() {
      if (this.step) {
        return this.workflowManager.currentStep.text;
      }
      return "";
    },
    componentTitle() {
      return "";
    },
    selectedComponent(){
      if (!this.step){
        return "";
      }
      return this.workflowManager.currentStep.getSelectedItem("component");
    },
    selectedSystem(){
      if (!this.step){
        return "";
      }
      return this.workflowManager.currentStep.getSelectedItem("system");
    },
    firstColumnActions() {
      const actions = this.workflowManager.getCurrentFilteredStepActions();
      if(this.workflowManager.currentStep.isSingleSelect() ){
        return actions.length <= 10 ? actions : actions.slice(0, Math.ceil(actions.length / 2));
      }
      return actions;
    },
    secondColumnActions() {
      if(this.workflowManager.currentStep.isSingleSelect() ){
      const actions = this.workflowManager.getCurrentFilteredStepActions();
      return actions.length > 10 ? actions.slice(Math.ceil(actions.length / 2)) : [];
      }
      return [];
    },
  },
  data() {
    return {
      selectedActions: [],
      selectedAction: null,
      previousSelectedAction: null,
      focusSet: false,
    
    };
  },
  watch: {
    'workflowManager.currentStep.actions': {
      deep: true,
      immediate: false,
      handler(actions) {
        actions.forEach(stepAction => {
          if (stepAction.selected) {
            this.onSelectedActionChange(stepAction);
          }
          
        });
      //  console.log(new Date().toLocaleString() + "stepComponent watcher");
      }
    }
  },
  methods: {
    
    ...mapActions(['setNavItems']),
    shouldFocus() {
      if (this.focusSet){
        console.log("shouldNOTFocus")
          return false;
      }
      console.log("shouldFocus")
      this.focusSet = true;
      return true;
    },
    onSelectedActionChange(targetStepAction) {
      this.focusSet = false;
      if (this.workflowManager.currentStep.isSingleSelect() && targetStepAction.selected){
        this.workflowManager.currentStep.actions.forEach(stepAction => {
          if (stepAction.id !== targetStepAction.id) {
            stepAction.selected = false;
            stepAction.showoutput = false;
          }
        });
      }else if (targetStepAction.action.groupIds && targetStepAction.action.groupIds.length > 0 && targetStepAction.selected){
        this.workflowManager.currentStep.actions.forEach(stepAction => {
          if (stepAction.id !== targetStepAction.id && stepAction.selected ) {
              if (stepAction.action.isInGroup(targetStepAction.action.id)) {
                stepAction.selected = false;
                stepAction.showoutput = false;
              }
          }
        });
      }
      targetStepAction.showoutput = targetStepAction.selected;
      if (targetStepAction.searchFilter){
        EventBus.emit('action-selected', targetStepAction.searchFilter.value);
      }else{
        EventBus.emit('action-selected', targetStepAction);
      }
      if (targetStepAction.selected && targetStepAction.cacheSelection) {
        this.workflowManager.addToCache(targetStepAction, this.step.stepName);
      }

    }
  }
}
</script>
<style scoped>
.form-radio-label,
.form-check-label {
  margin-left: 5px;
}

.grid-container {
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 10px;
  align-items: center;
  margin-top: 25px;
}

.checkbox-grid {
  display: flex;
  overflow-y: auto;
  max-height: 70vh;
  padding-left: 5px;
}

.column {
  flex: 1;
}

.grid-item {
  display: flex;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.supplemental-container {
  margin-left: 5%; 
}
.left-align{
}
</style>
