class DialogRowOutput {
    constructor(data) {
        this.output = data.output;
        this.columnOutputs = data.columnOutputs;
        
    }
    static create(data) {
        return new DialogRowOutput(data);
    }
}

export default DialogRowOutput;