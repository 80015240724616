  class Vehicle {
    constructor(data) {
        this.id = data.id;
        this.vin = data.vin;
        this.make = data.make;
        this.year = data.year;
        this.mileage = data.mileage;
    }
}

export default Vehicle;