<template>
  <Panel header="Repair Order Entry" style="margin-left: 20px">
    <div class="container">
      <div class="vehicle">
        <VehicleComponent :vehicleInfo="vehicle" />
      </div>
      <div class="repair-order">
        <RepairOrderComponent :repairOrderInfo="repairOrder" />
        <div>
          <Button @click="saveROInfo" label="Submit" style="margin-bottom: 10px" />
          <Button label="Clear" @click="reset" />
        </div>
      </div>
    </div>
  </Panel>
</template>
<script>
 
import RepairOrderComponent from '@/components/RepairOrderComponent.vue'
import VehicleComponent from '../components/VehicleComponent.vue';

import Vehicle from '@/Client/modules/pbadmin/models/Vehicle';
import {getRepairOrder} from '@/Client/modules/pbadmin/services/RepairOrderService'
import  RepairOrder from '@/Client/modules/pbadmin/models/RepairOrder';
import { mapActions } from 'vuex'

export default {
  name: 'Systems',
  components: {
    RepairOrderComponent,
    VehicleComponent
  },
 
  data() {
    return {
      repairOrder:RepairOrder,
      vehicle: Vehicle
    };
  },
  mounted() {
    this.user = this.getUser()
  },
  methods: {
    ...mapActions(['getUser']),

    saveROInfo() {
 
    },
    getVehicle(){
      getbluetoothinfo().then((vehicle) => {
        this.vehicle = vehicle
      });
    },
    reset() {
      this.repairOrder =RepairOrder
        this.vehicle = Vehicle
    }
  },
};
</script>
<style scoped> .container {
   display: flex;
   gap: 0.5rem;
   margin-left: 20px;
 }

 .vehicle,
 .repair-order {
   display: inline-block;
   width: 50%;
   /* adjust the width as needed */
 }
</style>
