<template>
    <Panel header="Repair Order Information">
      <div class="p-field">
        <label for="ronumber">RO#</label>
        <InputText v-model="repairOrderInfo.roNumber" id="ronumber" />
      </div>
      <div class="p-field">
        <label for="line">Line</label>
        <InputText v-model="repairOrderInfo.line" id="line" />
      </div>
    </Panel>
</template>

<script>
 
export default {
  props: {
    repairOrderInfo: {
      type: Object,
      required: true
    } 
  },
 
methods: {
 
},
};
</script>
