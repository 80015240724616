
class DialogControlSettings {
   constructor(data, updateId) {
      this.suppDataControlType = data.suppDataControlType;
      this.suppDataControlCategory = data.suppDataControlCategory;
      this.suppDataControlTag = data.suppDataControlTag;
      this.suppDataControlUnitType = data.suppDataControlUnitType;
      this.suppDataControlUnit = data.suppDataControlUnit;
      this.isDefaultForType = data.isDefaultForType;
      this.suppDataControlMask = data.suppDataControlMask;
      this.suppDataControlPlaceholder = data.suppDataControlPlaceholder
      this.MaskaSupport = this.MaskaMask();
      this.MaskaTokens = this.MaskaTokens();
     
   }
   static create(data, updateId) {
      return new DialogControlSettings(data, updateId);
   }
   MaskaTokens() {
      const primeVuePatterns = {
         '9': '[0-9]',
         'a': '[a-zA-Z]',
         '*': '[a-zA-Z\\d]'
     };
 
     let maskaTokens = '';
     for (let token in primeVuePatterns) {
         if (this.suppDataControlMask.includes(token)) {
            if (token == '9'){
               token = '0';
            }
             maskaTokens += `${token}:${primeVuePatterns[token]},`;
         }
     }
     return maskaTokens.slice(0, -1); // remove the trailing comma
  }
   MaskaMask() {
      let maskaEquivalent = '';
      
      for (let char of this.suppDataControlMask) {
          switch (char) {
              case 'a':
                  maskaEquivalent += 'L';  // L for letter in Maska
                  break;
              case '9':
                  maskaEquivalent += '#';  // 0 for number in Maska
                  break;
              case '*':
                  maskaEquivalent += 'A';  // A for alphanumeric in Maska
                  break;
              default:
                  maskaEquivalent += char;  // retain other characters as they are
                  break;
          }
      }
  
      return maskaEquivalent; //+this.suppDataControlUnit;
  }
  
   isSingleLineControl() {
      return (this.suppDataControlType == 'SingleLineInput');
   }
   isMultiLineControl() {
      return (this.suppDataControlType == 'MultiLineInput');
   }
   isSingleSelectControl() {
      return (this.suppDataControlType == 'SingleSelectInput');
   }
   isMultiSelectControl() {
      return (this.suppDataControlType == 'MultiSelectInput');
   }
   isRadioButtonControl() {
      return (this.suppDataControlType == 'RadioButton');
   }
   isCheckBoxControl() {
      return (this.suppDataControlType == 'CheckBox');
   }
   isCustomSearchFilter() {
      return (this.suppDataControlType == 'SearchFilter');
   }
}

export default DialogControlSettings;