
import Card from './Card';
import ActionOutput from './ActionOutput';
class Action {
    constructor(data) {
        if (data) {
            this.id = data.id;
            this.actionSummary = data.actionSummary;
            this.actionOutput = data.actionOutput;
            this.actionType = data.actionType;
            this.groupIds = data.groupIds ? data.groupIds : [];
            this.isGlobalAction = data.isGlobalAction;
            this.stepCount = data.stepCount;
            this.notes = data.notes;
            this.category = data.category;
            this.outputOrder = data.outputOrder;
            this.altOutputOrder = data.altOutputOrder;
        } else {
            this.id = null;
            this.actionSummary = "";
            this.actionOutput = "";
            this.actionType = 0;
            this.groupIds = [];
            this.isGlobalAction = false;
            this.stepCount = 0;
            this.notes = "";
            this.outputOrderIndex = 0;
            this.altOutputOrder = 0;
        }
        
    }
    static create(data) {
        return new Action(data);
    }
    asCard() {
        return new Card({
            id: this.id,
            name: this.actionSummary,
            description: this.actionSummary,
            workflows: []
        });
    }
 
    isInGroup(actionId) {
        if (this.groupIds){
        
            return this.groupIds.includes(actionId);
        }
        return false;
    }
}

export default Action;


