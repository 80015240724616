<template>
    <div class="button-toolbar">
        <Button v-if="enableFinish"  icon="pi pi-chevron-right" :disabled="disableNext" @click="nextStep" class="full-height-button"></Button>
        <Button v-if="!enableFinish" label="Finish" @click="finishStep" class="full-height-button"></Button>
    </div>
</template>

<script>
import EventBus from '@/Client/modules/pbadmin/event_bus/EventBus.js'
import { mapActions, mapState } from 'vuex'

export default {
    components: {
    },
    data() {
        return {
            selectedOption: 'Preview',
            options: ['Edit', 'Preview'],
            nextButtonEnabled: false,
            selectedAction: null,

        };
    },
    mounted() {

        EventBus.on('action-selected', this.allowNextStep);
        window.addEventListener('keydown', this.handleKeyDown);
       
    },
    beforeUnmount() {
        EventBus.off('action-selected', this.allowNextStep);
        window.removeEventListener('keydown', this.handleKeyDown);
    },
    props: {
        step: {
            type: [Object, null],
            default: null
        },
        startingStepId: {
            type: [String, null],
            default: null
        },
        onSummaryScreen: {
            type: Boolean,
            default: false
        },
        goToNextStep: {
            type: Function,
            required: true
        },
        goToPriorStep: {
            type: Function,
            required: true
        },
        goToSummary: {
            type: Function,
            required: true
        },
        onlyButtons: {
            type: Boolean,
            default: false
        }
    },
    computed: {

        disableNext() {
            if (!this.nextButtonEnabled) {
                return true;
            }
            if (this.step) {
                return !this.step.hasMoreSteps();
            }

            return true;
        },
       
        enableFinish() {
            if (this.step) {
                return this.step.hasMoreSteps();
            }
            return false;
        },
        
    },
    methods: {
        handleKeyDown(event) {
            
            if (event.altKey && event.key === 'n') {
                if (this.disableNext && this.enableFinish) {
                    this.showError("Unable to go to next step. Please select an action.", "Previous Step");
                } else if (!this.disableNext) {
                    this.nextStep();
                }else if (!this.enableFinish){
                    this.finishStep();
                }
                event.preventDefault();
            }
        },
        nextStep() {
           
            this.goToNextStep(this.selectedAction.toStepId);
            this.nextButtonEnabled = false;
        },
       
        finishStep() {
            this.goToSummary();
        },
        allowNextStep(selectedAction) {
            this.selectedAction=selectedAction;
           
            this.nextButtonEnabled = true;
        },
       
        showError(message,summary) {
            this.$toast.add({ severity: 'error', summary: summary, detail: message, life: 3000 });
        }
    }
}
</script>
