import StepAction from "./StepAction";
 
class Session {
    constructor(data) {
        //var userId:UUID, var id:UUID, var :UUID, var companyId:UUID, var selectedActions:List<StepDto>?, var story:String?,var cause:String?, var correction:String?)
        this.id = data.id;
        this.userId = data.userId,
        this.workflowId = data.workflowId;
        this.companyId=data.companyId;
        this.Steps =  Array.isArray(data.Steps) ? data.Steps.map(step => WorkflowStep.create(step)) : [];
        this.lastCompletedStepId = data.lastCompletedStepId;
        this.state = data.state? data.state : "New";
        this.lastModifiedOn = data.lastModifiedOn? data.lastModifiedOn : new Date().toISOString();
       
    }
    static newSession(data) {
        return new Session(data);
         
    }
    asCard() {
        return new Card({
            id: this.id,
            name: this.actionSummary,
            description: this.actionSummary,
            workflows: []
        });
    }
    startWorkflowSession(workflowId){
        this.workflowId = workflowId;
        this.state = "New";
        this.lastModifiedOn = new Date().toISOString();
    }
    addStepToSession(step){

        let index =  this.Steps.indexOf(step);
        if (index !== -1) {
          this.Steps[index] = step;
        } else {
          this.Steps.push(step);
        }
        this.lastCompletedStepId = step.id;
        this.state = "On Step "+step.stepText;
        this.lastModifiedOn = new Date().toISOString();
    }
    remoteStepFromSession(step){
        this.Steps.delete(step.id);
    }
     
}
export default Session;
 