
class ActionGroup {
    constructor(data) {
        this.stepId = data.stepId;
        this.groupId = data.groupId;
    }
    static create(data) {
        return new ActionGroup(data);
    }
    
}

export default ActionGroup;


