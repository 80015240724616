
<template>
  <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
    <div class="text-center mb-5">

      <div class="text-900 text-3xl font-medium mb-3">Welcome Back</div>
      <span class="text-600 font-medium line-height-3">Don't have an account?</span>
      <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Create today!</a>
    </div>

    <div>
      <label for="email1" class="block text-900 font-medium mb-2">User Name</label>
      <InputText id="email1" v-model="userName" type="text" class="w-full mb-3" />

      <label for="password1" class="block text-900 font-medium mb-2">Password</label>
      <InputText v-model="userPassword" id="password1" type="password" class="w-full mb-3" />

      <div class="flex align-items-center justify-content-between mb-6">
        <div class="flex align-items-center">
          <Checkbox id="rememberme1" :binary="true" v-model="checked" class="mr-2"></Checkbox>
          <label for="rememberme1">Remember me</label>
        </div>
        <a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Forgot password?</a>
      </div>
       <div>
        {{this.loginMessage}}
       </div>
      <Button label="Sign In" icon="pi pi-user" class="w-full" @click="login"></Button>
    </div>
  </div>
</template>
<script>
import { getFakeLogin, Login } from '@/Client/modules/pbadmin/services/UserService.js'
import { ref } from "vue";
export default {
  components: {

  },
  data() {
    return {
      userName: '',
      userPassword: '',
      loginMessage:'',
    }
  },
  methods: {
    login() {
      getFakeLogin(this.userName, this.userPassword).then((u) => {
        Login(this.userName,  this.userPassword).then((usr) => {
          this.$emit('logged-in', usr);
        })
      }).catch((error) => {
        this.loginMessage = error.response.data.message;
    });
    }
  }
}
</script>

<style scoped>
/* your styles here */
</style>


  
