<template>
    <div id="slideover-3" class="surface-overlay absolute top-0 right-0 shadow-2 w-full lg:w-6 h-full" v-if="isWIPSlideOutOpen" >
    <div class="flex flex-column h-full p-4">
        <div class="flex align-items-center justify-content-between mb-4">
            <span class="text-900 text-xl font-medium">Current saved work</span>
            <Button icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain"
            @click="toggleDeviceSlideOut"   ></Button>
        </div>
        <div class="border-2 border-dashed surface-border flex-auto overflow-y-auto p-3"></div>
    </div>
</div>
</template>
<script>
 import User from '@/Client/modules/pbadmin/models/User.js'
 import { mapState, mapActions } from 'vuex'
export default {
    name: 'workInProgress',
    
    props: {
        isWIPSlideOutOpen:{
            type: Boolean,
            required: true
        }
    },
    computed:{
        ...mapState({
      user: state => state.persisted.user
    }),
    },
    methods:{
        ...mapActions(['getUser']), 
        toggleDeviceSlideOut() {
            this.$emit('updateWIPSlideOut', !this.isWIPSlideOutOpen);
    },
    }
  }
  </script>