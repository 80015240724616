<template>

      <div class="p-col-12 p-lg-10">
        <div class="p-component">
            
        </div>
    </div>
  </template>
  <script>


  import { mapActions } from 'vuex'
  
  export default {
    name: 'Home',
    components: {
      
    },
   
    data() {
      return {
     
      };
    },
    mounted(){
      this.setNavItems(
        []
      )
    },
    methods: {
      ...mapActions(['getUser']),
      ...mapActions(['navItems']),
    ...mapActions(['setNavItems'])

      },
  };
  </script>
  <style scoped> 
  </style>