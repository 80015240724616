import RepairOrderLine from './RepairOrderLine';
import Vehicle from './Vehicle';

class RepairOrder {
    constructor(data) {
        this.id = data.id;
        this.lines = Array.isArray(data.lines) ? data.lines.map(line => new RepairOrderLine(line)) : [];
        this.roNumber = data.roNumber;
        this.vehicle =new Vehicle(data.vehicle);
        this.status = data.status;
        this.description = data.description;
    }
}
export default RepairOrder;