<template>
    <div class="button-toolbar">
        <Button icon="pi pi-chevron-left" :disabled="disablePrevious" @click="previousStep" class="full-height-button"></Button>
    </div>
 
</template>
  
<script>
import EventBus from '@/Client/modules/pbadmin/event_bus/EventBus.js'
import { mapActions, mapState } from 'vuex'

export default {
    components: {
    },
    data() {
        return {
            selectedOption: 'Preview',
            options: ['Edit', 'Preview'],
            nextButtonEnabled: false,

        };
    },
    mounted() {
        window.addEventListener('keydown', this.handleKeyDown);       
    },
    beforeUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown);
    },
    props: {
        step: {
            type: [Object, null],
            default: null
        },
        startingStepId: {
            type: [String, null],
            default: null
        },
        onSummaryScreen: {
            type: Boolean,
            default: false
        },
        goToNextStep: {
            type: Function,
            required: true
        },
        goToPriorStep: {
            type: Function,
            required: true
        },
        goToSummary: {
            type: Function,
            required: true
        },
        onlyButtons: {
            type: Boolean,
            default: false
        }
    },
    computed: {

      
        disablePrevious() {
            if (this.step && this.startingStepId) {
                return (this.step.id == this.startingStepId);
            }
            return (!this.onSummaryScreen);
        },
       
    },
    methods: {
        handleKeyDown(event) {
            if (event.altKey && event.key === 'p') {
                if (this.disablePrevious) {
                    this.showError("Unable to go to previous step", "Previous Step");
                } else {
                    this.previousStep();
                }
                event.preventDefault();
            }
           
        },
    
        previousStep() {
            this.goToPriorStep();
        },
        finishStep() {
            this.goToSummary();
        },
        showError(message,summary) {
            this.$toast.add({ severity: 'error', summary: summary, detail: message, life: 3000 });
        }
    }
}
</script>

  
  