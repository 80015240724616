

import axios from '@/config/axiosConfig'
import config from '@/config/config'
import store from '@/store'; 
import * as Sentry from '@sentry/browser'; 
import Action from '../models/Action';
 
 
export async function getAction(actionId) {
     
    const transaction = Sentry.startTransaction({
      op: 'api-getAction',
      name: 'Get Actiopn',
    });
    return new Promise((resolve, reject) => {
      actionId
        .get(`${config.apiEndpoint}/action/${workflowId}`)
        .then((response) => {
          transaction.finish();
          resolve(new Action(response.data));
        })
        .catch((error) => {
          Sentry.captureException(error);
          transaction.finish();
          reject(error);
        });
    });
  }
 
  export function getActions() {
    const transaction = Sentry.startTransaction({
      op: 'api-getAdtions',
      name: 'Get Actions',
    });
  
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiEndpoint}/action`)
        .then((response) => {
          transaction.finish();
          resolve(response.data.map((action) => new Action(action)));
        })
        .catch((error) => {
          Sentry.captureException(error);
          transaction.finish();
          reject(error);
        });
    });
  }
  
  
function getCompanyId(){
    if (!store.state.nonPersisted.company && store.state.persisted.user){
        store.state.nonPersisted.company = store.state.persisted.user.companies[0];
    }
    return store.state.nonPersisted.company.id;
}
function getCurrentSession(){
  return store.state.persisted.session;
}