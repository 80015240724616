<template>
  <!-- <div> -->
    <template v-if="isSingleLineControl">
      <template v-if="hasSuppDataControlMask">
        <InputText v-maska v-model="dialogParameterRowColumn.value"
          :data-maska="dialogParameterRowColumn.supplementalDataColumnControlSetting.MaskaSupport"
          :data-maska-tokens="dialogParameterRowColumn.supplementalDataColumnControlSetting.MaskaTokens"
          data-maska-reversed
          :placeholder="dialogParameterRowColumn.supplementalDataColumnControlSetting.suppDataControlMask"
          :style="{ width: maskedInputWidth, height: '1.5em', margin: '1px', textAlign: 'right' }" ref="inputMaskedRef" v-focus="focused" />
        {{ this.dialogParameterRowColumn.supplementalDataColumnControlSetting.suppDataControlUnit }}
      </template>
      <template v-else>
        <InputText v-model="dialogParameterRowColumn.value" rows="1" spellcheck="true"
          :style="{ width: maskedInputWidth, height: '1.5em', margin: '1px'}" ref="inputSingleLineRef" v-focus="focused"  />
      </template>
      <span class="control-label"> {{ getColumnControlLabel() }}</span>
    </template>
    <template v-else-if="isMultiLineControl">
      <div>
        <span class="control-label"> {{ getColumnControlLabel() }}</span>
      </div>
      <Textarea v-model="dialogParameterRowColumn.value" rows="5" cols="30" spellcheck="true" ref="inputMultiLineRef" v-focus="focused" />
    </template>
    <template v-else-if="isSingleSelectControl">
      <Dropdown v-model="dialogParameterRowColumn.value" :options="dialogParameterRowColumn.options"
        class="w-full md:w-14rem" :style="{ height: '2em', margin: '1px', paddingLeft:'5px'}" ref="inputDropdownRef" v-focus="focused"  />
      <span class="control-label"> {{ getColumnControlLabel() }}</span>
    </template>
    <template v-else-if="isMultiSelectControl">
      <MultiSelect v-model="dialogParameterRowColumn.values" :options="dialogParameterRowColumn.options" filter
        filterMatchMode="contains" ref="inputMultiSelectRef" v-focus="focused" display="chip"  class="md:w-10rem"   />
      <span class="control-label"> {{ getColumnControlLabel() }}</span>
    </template>
    <template v-else-if="isRadioButtonControl">
      <RadioButton v-model="dialogParameterRowColumn.value" :value="dialogRowLabel" :click="onSelectedRadioButtonChange()"
        ref="inputRadioRef" v-focus="focused"   class="control" />
      <label class="control-label ml-2">{{ getColumnControlLabel() }}</label>
    </template>
    <template v-else-if="isCheckBoxControl">
      <Checkbox v-model="dialogParameterRowColumn.values" class="control" :value="dialogRowLabel" ref="inputCheckRef" v-focus="focused" />
      <label class="control-label ml-2">{{ getColumnControlLabel() }}</label>
    </template>
  <!-- </div> -->
</template>

<script>
import { vMaska } from "maska"
import EventBus from '@/Client/modules/pbadmin/event_bus/EventBus.js'
import StepAction from '@/Client/modules/pbadmin/models/StepAction.js';
import DialogColumnValue from '@/Client/modules/pbadmin/models/DialogColumnValue.js';

export default {
  directives: { maska: vMaska },
  props: {
    dialogParameterRowColumn: { type: DialogColumnValue, required: true },
    dialogRowLabel: { type: String, required: false },
    currentStepAction: { type: StepAction, required: true },
    focused: { type: Boolean, required: true }
  },
 
 
  data() {
    return {
      singleSelect: null,
      value: null
    }
  },

  computed: {
    maskedInputWidth() {
      const defaultLength = 5;
      return ((this.hasSuppDataControlMask ? this.dialogParameterRowColumn.supplementalDataColumnControlSetting.suppDataControlMask.length : defaultLength) * 8 + 55) + 'px';
    },
    isSingleLineControl() {
      return this.checkControlType('isSingleLineControl');
    },
    isMultiLineControl() {
      return this.checkControlType('isMultiLineControl');
    },
    isSingleSelectControl() {
      return this.checkControlType('isSingleSelectControl');
    },
    isMultiSelectControl() {
      return this.checkControlType('isMultiSelectControl');
    },
    isRadioButtonControl() {
      return this.checkControlType('isRadioButtonControl');
    },
    isCheckBoxControl() {
      return this.checkControlType('isCheckBoxControl');
    },
    isCustomSearchFilter() {
      return this.checkControlType('isCustomSearchFilter');
    },
    hasSuppDataControlMask() {
      return this.dialogParameterRowColumn?.supplementalDataColumnControlSetting?.suppDataControlMask;
    }
  },
  methods: {
    getColumnControlLabel() {
      return this.dialogParameterRowColumn.getColumnControlLabel();
    },
    checkControlType(type) {
      return this.dialogParameterRowColumn?.supplementalDataColumnControlSetting?.[type]?.();
    },
    onSelectedRadioButtonChange() {
      if (this.dialogParameterRowColumn.value) {
        //  radioButtonEvent(this.dialogParameterRowColumn);
        //    EventBus.emit('suppdata-radiobuttonselected', this.dialogParameterRowColumn);
      }
    },
    radioButtonEvent(suppDataColumn) {
      if (this.isRadioButtonControl && this.dialogParameterRowColumn.rowId != suppDataColumn.rowId) {
        this.dialogParameterRowColumn.value = "";
      }
    },
   
  },
  watch: {
    'dialogParameterRowColumn.value': {
      deep: true,
      immediate: true,
      handler(value) {

        EventBus.emit('dialog-value-changed');
       
      }
    },
    
  },
  mounted() {
    //   EventBus.on('suppdata-radiobuttonselected', this.radioButtonEvent);
    

  },
  beforeUnmount() {
    //  EventBus.off('suppdata-radiobuttonselected', this.radioButtonEvent);
  }
};
</script>

<style scoped>
.control-label {
  display: inline-block;
  margin-left: .1em;
  line-height: 2;
  align-items: center;
  font-weight: 600!important;
} 
.control{
  margin-left: 10px;

}
.inputControl {
  width: 10ch !important;
  height: 1em !important;
}

/* Override background for .p-inputtext within your component */</style>