import { getStep, getToStepsForEdit, getStepActionsWithChildren } from '@/Client/modules/pbadmin/services/WorkflowStepService';
import { getWorkflow, proofText } from '@/Client/modules/pbadmin/services/WorkflowService';
import ActionGroup from '../models/ActionGroup';
import { joinWithCommasAnd } from '@/Client/modules/pbadmin/common/utils';
import { consoleLogWithSentry } from '@/Client/modules/pbadmin/observability/logging';
import StepActionListWithChildren from '@/Client/modules/pbadmin/models/StepActionListWithChildren';
class WorkflowManager {
    constructor(workflowId) {
        this.workflowId = workflowId;
        this.selectedActionsForSteps = [];
        this.filterStepList = [];
        this.selectedGroups = [];
        this.currentStep = null;
        this.cachedItems = new Map();
        this.workflow = null;

    }
    static create(workflowId) {
        return new WorkflowManager(workflowId);
    }
    getStartingStepId() {
        if (this.workflow) {
            return this.workflow.startingStepId;
        }
        return null;
    }
    async getWorkflow() {
        this.workflow = await getWorkflow(this.workflowId).then((workflow) => { return workflow });
        return this.workflow;
    }
    async getNextStep(toStepId) {
        
        if (toStepId == null || toStepId == undefined) {
            if (this.currentStep != null && this.currentStep != undefined) {
                return this.getStep(this.currentStep.getNextStepID());
            } else {
                return this.getStep(this.getStartingStepId())
            }
        } else {
            return this.getStep(toStepId);
        }
        return null;
    }
    async getStep(stepid) {
        if (stepid == null || stepid == undefined) {
            return null;
        }
        var step = await getStep(stepid).then((step) => { return step });
        //if a selected action on any step has a filter code, check the current loaded step to make sure
        // its not in the list, if it is get the first toStepID from the first action (all actions on the filtered step should have the same toStepID)
        if (this.filterStepList.some(code => code == step.stepName)) {
            return this.getStep(step.getNextStepId())
        }
        step.setCachedItems(new Map(this.cachedItems));
        if (this.currentStep) {
            step.fromStep = this.currentStep.id;
        }
        this.currentStep = step;
        this.mergeDialogsForStep(step);
   
        return this.currentStep;
    }

    async mergeDialogsForStep(step) {
        const dialogs = this.getDialogsFromSelectedActons();
        if (dialogs.length == 0) {
            return;
        }
        await step.actions.forEach(stepAction => {
            if (stepAction.supplementalDataDefinitions && !stepAction.action.isGlobalAction) {
                for (let i = 0; i < stepAction.supplementalDataDefinitions.length; i++) {
                    const origDialog = stepAction.supplementalDataDefinitions[i];
                    const dialog = dialogs.find(dlg => dlg.tag == origDialog.tag);
                    if (dialog && dialog.tag.toLowerCase() !="freeformtext") {
                        stepAction.supplementalDataDefinitions[i] = dialog;
                        return;
                    }
                }
            }
        });
    }
    getDialogsFromSelectedActons() {
        const dialogs = [];
        this.selectedActionsForSteps.forEach(step => {
            const selectedActions = step.selectedActions();
            selectedActions.forEach(action => {
                action.supplementalDataDefinitions.forEach(dialog => {
                    if (dialog) {
                        dialogs.push(dialog);
                    }
                });
            });
        });
        return dialogs;
    }
    getCurrentFilteredStepActions() {
        if (this.currentStep) {
            return this.currentStep.actions.filter(action =>
                !this.checkSelectedGroups(action, this.currentStep.id));
        }
        return [];
    }
    resetHistory(stepAction) {

        var step = this.selectedActionsForSteps.find(stp => stp.id == stepAction.stepId);
        if (step) {
            if (!this.selectedActionsForSteps) {
                this.selectedActionsForSteps = [];
                selectedActionsForSteps.push(step);
                return;
            } else {
                const index = this.selectedActionsForSteps.findIndex(stp => stp.id == step.id) + 1;
                if (index > 0) {
                        this.selectedActionsForSteps.splice(index);
                        this.currentStep = step;            
                }
            }
        }
        
    }
    async updateStepHistory() {
        var step = this.currentStep;
        //populate the filterStepList for any selected actions (hint groupid == action.id for now 12/5/23)
        await step.actions.forEach(stepAction => {
            if (stepAction.selected && stepAction.stepFilters && stepAction.stepFilters.length > 0) {
                if (this.filterStepList) {
                    this.filterStepList = this.filterStepList.concat(stepAction.stepFilters);
                } else {
                    this.filterStepList = stepAction.stepFilters;
                }
            }
            if (stepAction.selected && stepAction.action.groupIds && stepAction.action.groupIds.length > 0) {
                stepAction.action.groupIds.forEach(groupId => {
                    const group = ActionGroup.create({ 'groupId': groupId, 'stepId': step.id })
                    this.selectedGroups.push(group);

                });
            }
            if (stepAction.cacheSelection) {
                this.cachedItems.set(step.stepName.toLowerCase(), stepAction.unFilteredText());
            }
        });

        if (!this.selectedActionsForSteps) {
            this.selectedActionsForSteps = [];
            selectedActionsForSteps.push(step);
            return;
        }
        if (step.isSingleSelect()) {
            const index = this.selectedActionsForSteps.findIndex(stp => stp.id == step.id) + 1;
            if (index > 0) {
                if (this.selectedActionChanged) {
                    this.selectedActionsForSteps.splice(index);
                }
            }
        }
        if (!this.selectedActionsForSteps.some(stp => stp.id == step.id)) {
            this.selectedActionsForSteps.push(step)
        } else {
            const index = this.selectedActionsForSteps.findIndex(stp => stp.id == step.id);
            if (index > 0) {
                if (this.selectedActionChanged) {
                    this.selectedActionsForSteps[index] = step;
                }
            }

        }
    }
    checkSelectedGroups(stepAction, stepId) {
        if (this.selectedGroups && this.selectedGroups.length > 0) {

            return this.selectedGroups.some(group => group.groupId == stepAction.action.id && group.stepId != stepId);
        }
        return false;
    }
    async getOutputForSelectedActions() {
        const uniqueOutput = new Map();
      
        for (const step of this.selectedActionsForSteps) {
          const stepOutput = await step.selectedOutput();
      
          for (const item of stepOutput) {
            const key = `${item.outputCategory}-${item.actionOutput}`;
            if (!uniqueOutput.has(key)) {
              uniqueOutput.set(key, item);
            }
          }
        }
      
        const output = new Map();
      
        // Convert the unique items back into an array
        for (const [key, value] of uniqueOutput) {
          const category = value.outputCategory;
          if (!output.has(category)) {
            output.set(category, [value]);
          } else {
            output.get(category).push(value);
          }
        }
      
        for (const [key, value] of output) {
          let preText = joinWithCommasAnd(value).replace('  ', ' ');
          output.set(key, preText);
        }
      
        return output;
      }
      
    CurrentStepTitle() {
        if (this.currentStep) {
            return this.currentStep.getStepTitle();
        }
        return "";
    }
    addToCache(stepAction, key) {
        if (stepAction.cacheSelection) {
            this.cachedItems.set(key, stepAction.unFilteredText());
        }
    }
}
export default WorkflowManager;