<template>
    <Dialog v-model:visible="localShow" appendTo="body" :modal="true" 
            :breakpoints="{ '960px': '75vw', '640px': '100vw' }" 
            :style="{ width: '40vw' }" :closable="false" :showHeader="false">
        <div class="surface-ground px-4 py-8 md:px-6 lg:px-8">
            <div v-if="addNew" class="text-900 font-medium text-xl mb-3">
                Add Action
            </div>
            <div v-else class="text-900 font-medium text-xl mb-3">
                Edit Action
            </div>
            <div class="surface-card p-4 shadow-2 border-round p-fluid">
                <div class="grid formgrid p-fluid">
                    <div class="field mb-4 col-12 md:col-12">
                        <label for="display_text" class="font-medium text-900">
                            Action
                        </label>
                        <Dropdown v-model="selectedAction" filter :options="actionList" optionLabel="actionSummary"
                            :placeholder="actionListPlaceHolder" :loading="actionListLoading"
                            id="display_text" />
                    </div>
                    <div class="field mb-4 col-12 md:col-12">
                        <label for="output_text" class="font-medium text-900">
                            Output
                        </label>
                        <InputText v-model="editAction.action.actionOutput" id="output_text" type="text" />
                    </div>
                    <div class="field mb-4 col-12 md:col-12">
                        <label for="group_ids" class="font-medium text-900">
                            Linked actions
                        </label>
                        <MultiSelect v-model="selectedGroups" filter :options="actionOptions" optionLabel="label"
                            :placeholder="selectedGroupsPlaceholder" :loading="selectedGroupsLoading" :maxSelectedLabels="5"
                            class="w-full md:w-40rem" :disabled="isSingleSelect" id="group_ids" />
                    </div>
                    <div class="field mb-4 col-12 md:col-6">
                        <label for="to_step" class="font-medium text-900">
                            To Step
                        </label>
                        <Dropdown v-model="selectedToStep" filter :options="toStepList" optionLabel="stepText"
                            :placeholder="toStepListPlaceholder" :loading="toStepListLoading" class="w-full md:w-14rem"
                            id="to_step" />
                    </div>
                    <div class="field mb-4 col-12 md:col-6">
                        <label for="suppdata" class="font-medium text-900">
                            Supplemental Data Input
                        </label>
                        <MultiSelect v-model="selectedDialogs" filter :options="dialogList"
                            optionLabel="suppDataDescription" :placeholder="dialogListPlaceholder"
                            :loading="dialogListLoading" :maxSelectedLabels="3" class="w-full md:w-40rem" id="suppdata" />
                    </div>
                    <div class="flex align-items-center cb-item">
                        <Checkbox v-model="applyToAll" id="apply_to_all" :disabled="isCheckboxDisabled" :binary="true" />
                        <label for="apply_to_all" class="ml-2">
                            Apply to all workflow steps
                        </label>
                    </div>
                    <div class="surface-border border-top-1 opacity-50 mb-4 col-12"></div>
                    <div class="field mb-4 col-12">
                        <label for="notes" class="font-medium text-900">
                            Notes
                        </label>
                        <Textarea v-model="editAction.action.notes" id="notes" :autoResize="true" :rows="5"></Textarea>
                    </div>
                    <div class="surface-border border-top-1 opacity-50 mb-4 col-12"></div>
                </div>
            </div>
        </div>
        <template #footer>
            <div class="border-top-1 surface-border pt-3 flex">
                <Button icon="pi pi-times" @click="applyChanges" label="Cancel" class="p-button-outlined w-6 mr-2"></Button>
                <Button icon="pi pi-check" @click="closeEditor" label="Save" class="w-6 ml-2"></Button>
            </div>
        </template>
    </Dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import EventBus from '@/Client/modules/pbadmin/event_bus/EventBus.js'

export default {
    name: 'stepActionEditor',
    components: {},
    props: {
        workflowEditManager: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            editAction: null,
            toStepListLoading: false,
            toStepListPlaceholder: "Loading...",
            selectedGroupsPlaceholder: "Loading...",
            selectedGroupsLoading: true,
            localShow: false,
            dialogListLoading: true,
            dialogListPlaceholder: "Loading...",
            actionOptions: [],
            selectedGroups: [],
            isSingleSelect: false,
            toStepList: [],
            selectedToStep: null,
            selectedDialogs: [],
            dialogList: [],
            applyToAll: false,
            selectedAction: null,
            actionList: [],
            addNew: false,
            actionListPlaceHolder: "Loading...",
            actionListLoading: true,
        };
    },
    mounted() {
        EventBus.on('show-stepActionEditor', this.showEditor);
        EventBus.on('show-stepActionAdd', this.showAdd);
        this.isSingleSelect = this.workflowEditManager.step.isSingleSelect();

    },
    unmounted() {
        EventBus.off('show-stepActionEditor', this.showEditor);
        EventBus.off('show-stepActionAdd', this.showAdd);
    },
    computed: {
        isCheckboxDisabled() {
            return this.editAction.action.stepToActionCount <= 1;
        }
    },
    methods: {
        applyChanges() {
            //save the changes 
            this.closeEditor();
        },
        closeEditor() {
            this.localShow = false;
            this.editAction = null;
            this.toStepListLoading = false;
            this.toStepListPlaceholder = "Loading...";
            this.selectedGroupsPlaceholder = "Loading...";
            this.selectedGroupsLoading = true;
            this.dialogListLoading = true;
            this.dialogListPlaceholder = "Loading...";
            this.selectedGroups = [];
            this.isSingleSelect = false;
            this.selectedToStep = null;
            this.selectedDialogs = [];
            this.applyToAll = false;
            this.selectedAction = null;
            this.addNew = false;
            this.actionListPlaceHolder = "Loading...";
            this.actionListLoading = true;
        },
        async showEditor(stepAction) {
            this.editAction = stepAction;
            this.showDialog();
            this.localShow = true;
            this.addNew = false;
        },
        async showAdd(stepAction) {
            this.editAction = stepAction;
            this.showDialog();
            this.localShow = true;
            this.addNew = true;
        },
        async showDialog() {
            if (this.editAction) {
                this.workflowEditManager.getActions().then((actions) => {
                    this.actionList = actions;
                    this.actionListLoading = false;
                    this.actionListPlaceHolder = "Select an action"

                });

                this.workflowEditManager.getDialogList().then((dialogList) => {
                    this.dialogList = dialogList;
                    this.dialogListPlaceholder = "Select a dialog"
                    this.dialogListLoading = false;
                    this.selectedDialogs = this.dialogList.filter((dialog) => {
                        return this.editAction.hasDialogCode(dialog.tag);
                    });
                });
                //get the action list from the current step
                const actions = this.workflowEditManager.step.actions.filter((actionOption) => {
                    return actionOption.action.id !== this.editAction.action.id;
                });
                //convert the action list to options
                const options = actions.map((stepAction) => {
                    return {
                        label: stepAction.action.actionSummary,
                        value: stepAction.action.id,
                        isGlobalAction: stepAction.action.isGlobalAction,
                        groupIds: stepAction.action.groupIds,
                    };
                });
                //filter out global actions and set the action options list
                this.actionOptions = options.filter((actionOption) => {
                    return actionOption.isGlobalAction === false;
                });
                //get the to step list and set the selected value
                this.toStepList = await this.workflowEditManager.getToStepList(this.workflowEditManager.step.id)
                this.selectedToStep = this.toStepList.find((step) => {
                    return step.id === this.editAction.toStepId;
                });
                this.toStepListLoading = false;
                this.toStepListPlaceholder = "Select a step"
                //get the selected groups 
                this.selectedGroups = options.filter((actionOption) => {
                    return this.editAction.action.hasGroups(actionOption.groupIds);
                });
                this.selectedGroupsPlaceholder = "Select a linked action"
                this.selectedGroupsLoading = false;
                this.selectedAction = this.editAction.action;
 
            }
        },
    },
};
</script>

<style scoped>
.cb-item {
    margin-bottom: 2.0rem;
}
</style>
