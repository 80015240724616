<!--prop object should be array of StepActions that represents the selected actions for each step-->
<!--it should display the step title, then the selected action(s).  Under each action it would load the supp data dialog-->
<!--
     Verification
            x Unable to verify
     System Wrapup
            x Axles and CV Joints
    UnVerfied 
            x Perfoemed TSB - enter doc number / desc(U1tsb.1)
                    TSB              Description
              ---------------   ---------------------  +/-
              ---------------   ---------------------  +/-
              ---------------   ---------------------  +/-
    UnVerified wrapup
            x Further diagnosis needed(W1.3)
            x Odometer out(W1.1)
              -------------------------
            x Unable to duplicate/verify concern(W1.5)



if an action is unchecked it is not used in the output text but remains in the list for the user to recheck
clicking on a step title should load the step page
supp data dialog should work as normail (add/remove rows if allowed, edit/enter values or select)

        -->
        <!-- CustomTreeSelect.vue -->
<template>
    <div class="scrollable-container">
        <Accordion v-if="hasSteps"
            :activeIndex="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40]"
            :multiple="true">
            <template v-for="(step) in selectedSteps">
                <AccordionTab :header="step.stepText">
                    <div v-for="stepAction in step.actions" :key="stepAction.id" class="child-item">
                        <template v-if="stepAction.selected">
                            <!-- Display the action's question with checkbox -->
                            <Checkbox v-model="stepAction.showoutput" :binary="true" :inputId="stepAction.id"
                                :name="stepAction.id" :value="stepAction.showoutput"
                                @update:modelValue="onSelectedActionChange(stepAction)" />
                            <label :for="stepAction.id" class="form-radio-label">{{
                                stepAction.filteredQuestion(selectedComponent, selectedSystem) }}</label>
                            <div v-if="stepAction.showoutput"
                                v-for="(dialogParameter, index) in stepAction.supplementalDataDefinitions" :key="index"
                                class="supplemental-container ">
                                <supplementalDataDialogComponent  v-if="!dialogParameter.isSearchDialog()" :dialogParameter="dialogParameter" :currentStepAction="stepAction" :ignoreFocus="true" />
                                <supplementalDataControlCustomSearchHistoryView v-if="dialogParameter.isSearchDialog()" :currentStepAction="stepAction"  />
                            </div>
                        </template>
                    </div>
                </AccordionTab>
            </template>
        </Accordion>
    </div>
</template>
          
<script>
import supplementalDataDialogComponent from '@/newComponents/supplementalDataComponents/supplementalDataDialogComponent.vue';
import EventBus from '@/Client/modules/pbadmin/event_bus/EventBus.js'
import supplementalDataControlCustomSearchHistoryView from '@/newComponents/supplementalDataComponents/supplementalDataControlCustomSearchHistoryView.vue';
export default {
    name: "selectedActions",
    props: {
        selectedSteps: {
            type: Object,
            default: () => ([])
        },
        onIndexs: {
            type: Object,
            default: () => ([]),
            required: true
        }
    },
    components: {
        supplementalDataDialogComponent,
        supplementalDataControlCustomSearchHistoryView,
    }, mounted() {

    },

    computed: {
        hasSteps() {
            if (this.selectedSteps) {
                return (this.selectedSteps.length > 0);
            }
            return false;

        },
        componentTitle() {
            return "";
        },
        lastStepIndex() {
            if (this.selectedSteps) {
                return (this.selectedSteps.length - 1);
            }
            return 0;
        },
        selectedComponent() {
            if (!this.workflowManager) {
                return "";
            }
            return this.workflowManager.getSelectedItem("component");
        },
        selectedSystem() {
            if (!this.workflowManager) {
                return "";
            }
            return this.workflowManager.getSelectedItem("system");
        },
    },
    data() {
        return {
            selectedNodes: [],
            expandedKeys: {}
        };
    },
    methods: {
        isDisabled(isSingleSelect) {
            if (isSingleSelect) {
                return 'disabled';
            }
            return ''
        },
        onSelectedActionChange(stepAction) {
            EventBus.emit('selected-action-showoutput-changed', stepAction);
        },
    },
};
</script>