class RepairOrderLine {
    constructor(data) {
        this.id = data.id;
        this.lineId = data.lineId;
        this.complaint = data.complaint;
        this.cause = data.cause;
        this.correction = data.correction;
        this.techNotes = data.techNotes;
        
    }
}

export default RepairOrderLine;