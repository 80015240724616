import DialogColumnValue from "./DialogColumnValue";
import store from '@/store'; // Import the Vuex store
import DialogRowOutput from "./DialogRowOutput";

class DialogParameterRow {
    constructor(data,AdditionalSuppDataColumns) {
        this.id = data.id;
        this.suppDataRowLabel = data.suppDataRowLabel;
        this.suppDataRowOrder = data.suppDataRowOrder;
        this.suppDataColumns = Array.isArray(data.suppDataColumns) ? data.suppDataColumns.map(column => DialogColumnValue.create(column,data.id)) : [];
        this.suppDataRowOutput = data.suppDataRowOutput;
    }
    static create(data) {
       return new DialogParameterRow(data);
    }
    setColumnValue(value, id, index) {
 
    }
    getRowLabel(){
        if (this.suppDataRowLabel){
            const component = store.state.nonPersisted.component;
            const system  = store.state.nonPersisted.system;
            return this.suppDataRowLabel.replace('{selected:component}', component).replace('{selected:system}', system)
        }                                     
        return this.suppDataRowLabel;
    }
    clone(newId){
        let clonedRow = {
            id:  newId,
            suppDataRowLabel: this.suppDataRowLabel,
            suppDataRowOrder: this.suppDataRowOrder+1,
            suppDataColumns: this.suppDataColumns,
            suppDataRowOutput: this.suppDataRowOutput
        }
        return DialogParameterRow.create(clonedRow);
    }
    getColumnHeader(){

    }
     getOutputText(stepCategory) {
        var rowOutputData = {
            output:  "",
            columnOutputs: []
        }
       var dialogOutput = DialogRowOutput.create(rowOutputData);
        var rowOutputText="";
        if (this.suppDataRowOutput){
            rowOutputText = this.suppDataRowOutput;
        }
        dialogOutput.output = rowOutputText;
        var colValues=[];
        var idx = 0;
        this.suppDataColumns.forEach(column => {
            var columnOutput = column.getColumnOutput(stepCategory);
            if (columnOutput && columnOutput.output && columnOutput.output.length > 0){
                colValues[idx] = columnOutput;
                idx++;
            }
        });
        if (colValues.length > 0){
            dialogOutput.columnOutputs = colValues;
        }
        
       return dialogOutput;
      
    }
    getColumnValue(id) {
       
    }
    getColumnValueByIndex(index) {
       
    }
}

export default DialogParameterRow;