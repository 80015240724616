<template>
  <div>
    <label for="themeToggle">Toggle Theme:</label>
    <InputSwitch
      v-model="isDarkTheme"
      :change="toggleTheme"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      isDarkTheme: true,  
    };
  },
  methods: {
    toggleTheme() {
     
      this.isDarkTheme = !this.isDarkTheme;
      
      if (this.isDarkTheme) {
        this.$primevue.changeTheme('md-dark-indigo', 'md-light-indigo', 'dark-theme-link', () => {});
      } else {
        this.$primevue.changeTheme('md-dark-indigo', 'md-light-indigo', 'light-theme-link', () => {});
      }
    },
  },
};
</script>