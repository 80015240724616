
import DialogControlSettings from "./DialogControlSettings";
import DialogColumnOutput from "./DialogColumnOutput";

import store from '@/store'; // Import the Vuex store
class DialogColumnValue {
    constructor(data,rowId) {
        this.suppDataColumnLabel= data.suppDataColumnLabel;
        this.supplementalDataColumnControlSetting = DialogControlSettings.create(data.supplementalDataColumnControlSetting);
        this.suppDataDialogControlMacro = data.suppDataDialogControlMacro;
        this.suppDataControlLabel = data.suppDataControlLabel;
        this.ListOptions = data.ListOptions;
        this.rowId = rowId;
        this.value="";
        this.values=[];
        this.boolean_value=false;
        this.options =data.options;
        this.suppDataColumnOutput = data.suppDataColumnOutput;
        this.suppDataColumnOutputCategory = data.suppDataColumnOutputCategory;
    }
    static create(data,rowid) {
       return new DialogColumnValue(data,rowid);
       
    }
    getColumnLabel(){
        if (this.suppDataColumnLabel){
            const component = store.state.nonPersisted.component;
            const system  = store.state.nonPersisted.system;
            return this.suppDataColumnLabel.replace('{selected:component}', component).replace('{selected:system}', system)
        }                                     

        return this.suppDataColumnLabel;
    }
    getColumnControlLabel(){
        if (this.suppDataControlLabel){
        const component = store.state.nonPersisted.component;
        const system  = store.state.nonPersisted.system;
        return this.suppDataControlLabel.replace('{selected:component}', component).replace('{selected:system}', system)
        }
        return this.suppDataControlLabel;
    }
    getColumnOutput(stepCategory){
        var result = "";
        var suffix = "";
       
        let data = {
            output:  '',
            outputCategory: (this.suppDataColumnOutputCategory || this.suppDataColumnOutputCategory=='')?this.suppDataColumnOutputCategory:stepCategory
        }
        var columnOutput = DialogColumnOutput.create(data);
        
        if (!this.value && this.values.length ==0){
            return columnOutput;
        }
        if (this.supplementalDataColumnControlSetting && this.supplementalDataColumnControlSetting.suppDataControlUnit){
            suffix = " "+this.supplementalDataColumnControlSetting.suppDataControlUnit;
        }
        if (this.supplementalDataColumnControlSetting.isMultiSelectControl()|| this.supplementalDataColumnControlSetting.isCheckBoxControl()){
            this.value = this.values.join(' ');
        }
        if (!this.suppDataColumnOutput){
            
            return this.value +suffix;
         }
       const rtValue =  this.suppDataColumnOutput.replace(this.suppDataDialogControlMacro, this.value) +suffix;
       columnOutput.output = rtValue;
        
       return columnOutput;
       
    }
   /* getColumnOutput(){
        var label = this.getColumnLabel()?this.getColumnLabel():this.getColumnControlLabel()?this.getColumnControlLabel():"";
        var result = "";
        if (this.supplementalDataColumnControlSetting.isMultiSelectControl()|| this.supplementalDataColumnControlSetting.isCheckBoxControl()){
            result = this.values.join(' ');
        }
        else{
            result = this.value;
        }
        if (result == null || result == undefined || result == "")
        {
             return "";
        } 
        if (label == null || label == undefined || label == "" ){
            return result.replace(/,/g, '');;
        }
        return label + ":" + result;
    }*/
}
export default DialogColumnValue;