<template>
  <div class="summary-container">
    <div v-for="[key, value] in myMap" :key="key">
      <label :for="key">{{ formatt(key) }}</label>
      <div class="flex flex-wrap justify-content-between align-items-center gap-3 mt-3">
        <Textarea v-if="textAreas[key]" v-model="textAreas[key]" rows="10" cols="95" spellcheck="true" class="no-resize" />
        <Skeleton v-if="!textAreas[key]" width="50rem" height="15rem"></Skeleton>
      </div>
      
      <div class="flex flex-wrap mt-2">
        <small class="p-error" id="editor-error">{{ errorMessage || '&nbsp;' }}</small>
        <Button label="Send to DMS" @click="copyToClipboard(key)" />
      </div>
      <br />
    </div>
  </div>
  <Dialog v-model:visible="showDialg" appendTo="body" modal :breakpoints="{'1000px': '75vw', '1000px': '110vw'}" :style="{width: '80vw', height:'95dvh'}" header="Send To DMS" :draggable="false" :resizable="false">
    <template #header>
            <div class="flex flex-column gap-0">
                <h1 class="m-0 text-900 font-semibold text-xl line-height-1">Sent to DMS</h1>
                <span class="text-600 text-base"></span>
            </div>
    </template>
    <form class="flex flex-column gap-1 mt-1">
        <div  v-for="[key, value] in myMap" :key="key">
            <label for="cardholder" class="block mb-1 text-color text-base">{{ formatt(key)}}</label>
            <span class=" w-full">
                <Textarea v-model="textAreas[key]" rows="7" cols="110" spellcheck="true" class="no-resize" style="font-size: medium;" />
            </span>
        </div>
       
    </form>
    <template #footer>
        <div class="flex gap-1 justify-content-end border-top-1 surface-border pt-3">
            <Button label="Done" @click="showDialg = false" ></Button>
        </div>
    </template>
</Dialog>
</template>
<script>
import {consoleLogWithSentry} from '@/Client/modules/pbadmin/observability/logging';
import { mapState, mapActions } from 'vuex'
import { capitalizeFirstLetter } from '@/Client/modules/pbadmin/common/utils';
import config from '@/config/config';
import { getSummary } from '@/Client/modules/pbadmin/services/WorkflowService';
import WorkflowManager from '@/Client/modules/pbadmin/manager/WorkflowManager';
import { watch } from 'vue';
import EventBus from '@/Client/modules/pbadmin/event_bus/EventBus.js'
import axios, { formToJSON } from 'axios';
import {  proofText } from '@/Client/modules/pbadmin/services/WorkflowService';

export default {
  name: 'stepSummary',
  components: {

  },
  props: {
    workflowManager: {
      type: WorkflowManager,
      required: true,
    },
    onSummaryScreen: {
      type: Boolean,
      required: true,
    },

  },
  data() {
    return {
      errorMessage: null,
      story: '',
      prompt: config.chatGtpPromptForGrammer,
      modalText: '',
      showDialog: false,
      tries: [],
      attempt: 1,
      myMap: new Map(),
      textAreas: {},
      eventListenersRegistered : false,
      showDialg:false,
      dialogCategory:"",
      dialogOutput:"",
    };
  },
  mounted() {
    this.sendToAI();
    this.registerEventListeners() 
  },
  beforeUnmount() {
     this.unregisterEventListeners();
  },

  computed: {

    ...mapState({
      user: state => state.persisted.user,
      session: state => state.persisted.session
    }),

    componentName() {
      return "";
    }
  },
  methods: {
    registerEventListeners() {
    if (!this.eventListenersRegistered) {
     // EventBus.on('selected-action-showoutput-changed', this.sendToAI);
     // EventBus.on('dialog-value-changed', this.sendToAI);
     // this.eventListenersRegistered = true;
    }
  },
  formatt(value){
    return capitalizeFirstLetter(value);
  },
  async copyToClipboard(key) {
      try {
        await navigator.clipboard.writeText(this.textAreas[key]);
        this.dialogCategory= key;
        this.dialogOutput =this.textAreas[key];
        this.showDialg = true;
      } catch (err) {
        // Handle the error (e.g., Clipboard API not available)
        this.errorMessage = 'Failed to copy';
      }
    },
  unregisterEventListeners() {
 
  },
  
    resetPrompt() {
      this.prompt = config.chatGtpPromptForGrammer;
    },
    getstory(key) {
      const results = this.myMap.get(key);
      return results;
    },
    async sendToAI() {
      if (!this.onSummaryScreen){
        return;
      }
      this.myMap = await this.workflowManager.getOutputForSelectedActions();
      this.myMap.forEach(async (value, key) => {    
            if (this.textAreas[key]){
              this.textAreas[key]  = await proofText(value, this.textAreas[key]);
              
            }else{
              this.textAreas[key]  = await proofText(value, "");
            }
            
            consoleLogWithSentry( "info", "Category:"+key+" preText: " + value,"postText: " +  this.textAreas[key]);
      });
      //output.push({role:"user", content: this.prompt});

      // const outputs =  this.SelectedActionList.map(step => step.selectedOutput("")).join(' and ');
      //  this.story =  'verified concern and removed and inspected Axle Shaft Bearing and found no applicable Technical Service Bulletins and checked Axle Shaft Bearing for binding and inspected <a<span style="color: blue; text-decoration: underline;">{_dialog}</span> and Axle Shaft Bearing corroded and Axle Shaft Bearing loose and road tested to verify proper repair and reinstalled Axle Shaft Bearing and torqued all bolts to factory specifications'
      //  this.session.selectedOutput = output;
      //  this.session.prompt = this.prompt
      //  this.session.state="finished";

    },

    reloadPriorAIResults(step) {
      this.story = step.story;
      this.prompt = step.prompt;
    },
    checkForSpecialText(event) {
      if (event.target.classList.contains('blue-text')) {
        this.modalText = event.target.innerText;
        this.showDialog = true;
      }
    },
    updateText() {
      // Update the blue text in the editor
      // You might need a more sophisticated way of updating the text
      this.text = this.text.replace(/Blue Text/g, this.modalText);
    },
    closeDialog() {
      this.showDialog = false;
    },
     
     
  },
};
</script>
<style scoped>
.no-resize {
  resize: none;
  overflow: auto;
}
</style>