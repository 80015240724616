

import axios from '@/config/axiosConfig'
import config from '@/config/config'
import Workflow from '@/Client/modules/pbadmin/models/Workflow'
import Session from '@/Client/modules/pbadmin/models/Session'
import WorkflowStep from '@/Client/modules/pbadmin/models/WorkflowStep'
import store from '@/store'; 
import * as Sentry from '@sentry/browser'; 
 
 
export async function getWorkflow(workflowId) {
     
    const transaction = Sentry.startTransaction({
      op: 'api-getWorkflow',
      name: 'Get Workflow',
    });
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiEndpoint}/${getCompanyId()}/workflow/${workflowId}`)
        .then((response) => {
          transaction.finish();
          resolve(new Workflow(response.data));
        })
        .catch((error) => {
          Sentry.captureException(error);
          transaction.finish();
          reject(error);
        });
    });
  }
   
  export function getSummary() {
    const transaction = Sentry.startTransaction({
      op: 'api-getSummary',
      name: 'getSummary',
    });
  const session = getCurrentSession();

    return new Promise((resolve, reject) => {
      axios
      .post(`${config.apiEndpoint}/step/summary`, session)
        .then((response) => {
          transaction.finish();
          resolve(new Session(response.data));
        })
        .catch((error) => {
          Sentry.captureException(error);
          transaction.finish();
          reject(error);
        });
    });
  }
  export function getWorkflows(componentid) {
    const transaction = Sentry.startTransaction({
      op: 'api-getWorkflows',
      name: 'Get Workflows',
    });
  
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiEndpoint}/${getCompanyId()}/workflows/${componentid}`)
        .then((response) => {
          transaction.finish();
          resolve(response.data.map((workflow) => new Workflow(workflow)));
        })
        .catch((error) => {
          Sentry.captureException(error);
          transaction.finish();
          reject(error);
        });
    });
  }
  
  export function getSystemWorkflows(systemid) {
    const transaction = Sentry.startTransaction({
      op: 'api-getSystemWorkflows',
      name: 'Get System Workflows',
    });
  
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiEndpoint}/${getCompanyId()}/system/workflows/${systemid}`)
        .then((response) => {
          transaction.finish();
          resolve(response.data.map((workflow) => new Workflow(workflow)));
        })
        .catch((error) => {
          Sentry.captureException(error);
          transaction.finish();
          reject(error);
        });
    });
  }
   
  export function proofText(textToProof, otherResults) {
    const transaction = Sentry.startTransaction({
      op: 'api-getSystemWorkflows',
      name: 'Get System Workflows',
    });
  
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${config.apiEndpoint}/${getCompanyId()}/system/workflows/translate`,
          { requestContent: textToProof , priorResults:otherResults}, // Request body
          {
          headers: { 'Content-Type': 'application/json' },
          responseType: 'json'
          })
        .then((response) => {
          transaction.finish();
         
          resolve(response.data.text);
        })
        .catch((error) => {
          Sentry.captureException(error);
          transaction.finish();
          reject(error);
        });
    });
  }
function getCompanyId(){
    if (!store.state.nonPersisted.company && store.state.persisted.user){
        store.state.nonPersisted.company = store.state.persisted.user.companies[0];
    }
    return store.state.nonPersisted.company.id;
}
function getCurrentSession(){
  return store.state.persisted.session;
}