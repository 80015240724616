<template>
    <section v-if="isUserSlideOutOpen" id="slideover-11" style="max-height: 80%;"
              class="surface-overlay h-full absolute top-0 right-0 shadow-2 w-24rem">
        <div class="flex flex-column justify-content-between h-full">
            <form>
                <div class="flex align-items-start bg-blue-700 justify-content-between p-3 mb-4">
                    <header>
                        <h1 class="text-0 mt-0 text-xl font-medium mb-1 text-white">{{ userFullName }} </h1>
                        
                    </header>
                    <div>
                        <Button icon="pi pi-times" @click="toggleUserSlideOut"
                            class="p-button-rounded p-button-text text-white-alpha-70 p-button-plain hover:bg-blue-500"
                            pStyleClass="#slideover-11" leaveToClass="hidden" leaveActiveClass="fadeoutright"
                            ></Button>
                    </div>
                </div>
                <div class="px-4">
                    <h3 class="text-base font-semibold mt-0 mb-0 text-color">Email Address</h3>
                    <div class="p-inputgroup mt-2">
                        <span class="p-inputgroup-addon"><i class="pi pi-file"></i></span>
                        <InputText v-model=this.user.email type="text" />
                    </div>
                </div>
                <div class="px-4">
                    <h3 class="text-base font-semibold mt-0 mb-0 text-color">First Name</h3>
                    <div class="p-inputgroup mt-2">
                        <span class="p-inputgroup-addon"><i class="pi pi-file"></i></span>
                        <InputText v-model=this.user.first type="text" />
                    </div>
                </div>
                <div class="px-4">
                    <h3 class="text-base font-semibold mt-0 mb-0 text-color">Last Name</h3>
                    <div class="p-inputgroup mt-2">
                        <span class="p-inputgroup-addon"><i class="pi pi-file"></i></span>
                        <InputText v-model=this.user.last type="text" />
                    </div>
                </div>
            </form>
            <div class="mx-4 py-4 border-top-1 surface-border flex">
                <Button  type="button" class="w-full" @click="saveProfileChanges"  :loading="loading" >
                    <span class="w-full flex justify-content-center align-items-center gap-3 ">
                        <i class="pi pi-send"></i>
                        <span class="font-bold" >Save</span>
                    </span>
                </Button>
            </div>
        </div>
    </section>
</template>
<script>
 import User from '@/Client/modules/pbadmin/models/User.js'
 import {saveProfile} from '@/Client/modules/pbadmin/services/UserService.js'
 import { mapState, mapActions } from 'vuex'
import themeSwitcher from './themeSwitcher.vue'
export default {
    name: 'userProfile',
    components: {
        themeSwitcher
    },
    data() {
        return {
            loading: false
        };
    },
    props: {
        isUserSlideOutOpen:{
            type: Boolean,
            required: true
        }
    },
    computed:{
        ...mapState({
      user: state => state.persisted.user
    }),
        userFullName(){
            return this.user.first+" "+this.user.last;
        }
    },
    methods:{
        ...mapActions(['getUser','setUser']), 
        toggleUserSlideOut() {
            this.$emit('updateUserSlideOut', !this.isUserSlideOutOpen);
    },
    saveProfileChanges(){

        this.loading =true;
        var newUser ={"id":this.user.id, "first":this.user.first,"last":this.user.last, "email":this.user.email}
        saveProfile(newUser).then((newUser)=>{
            this.setUser(newUser);
            this.loading =false;
        })
    },
   
    }
  }
  </script>