import { getStep, getToStepsForEdit } from '@/Client/modules/pbadmin/services/WorkflowStepService';
import { getWorkflow } from '@/Client/modules/pbadmin/services/WorkflowService';
import { getDialogByCode,getDialogList } from '../services/DialogService';
import { v4 as uuidv4 } from 'uuid';
import { getActions } from '../services/ActionService';

class WorkflowEditManager {
    constructor(stepId,step) {
    
        this.stepId = stepId;
        this.step =step;
        this.editStep =step;
        this.stepChain =[];
        this.editSessionId = uuidv4();
        this.dialogList=[];
        this.actions = [];
        this.getDialogList();
        this.getToStepList();
        this.getActions();
    }
    static create(stepId, step) {
        return new WorkflowEditManager(stepId,step);
    }

    async getWorkflow(workflowId) {
        var workflow = await getWorkflow(workflowId).then((workflow) => { return workflow });
        return workflow;
    }
    async getStep() {
        if (! this.step){
            this.step = await getStep(this.stepId).then((step) => { return step });
        }
        return step;
    }
    async getToStepList() {
        if (! this.stepChain || this.stepChain.length === 0){
            this.stepChain = getToStepsForEdit(this.stepId).then((steps) => { return steps });
        }
        return this.stepChain;
        
    }
    async getDialogList() {
        if (!this.dialogList || this.dialogList.length === 0){
            this.dialogList = await getDialogList().then((dialogs) => { return dialogs });
        }
        return this.dialogList;
         
    }
    async getActions() {
        if (!this.actions || this.actions.length === 0){
            this.actions = await getActions().then((actions) => { return actions });
        }
        return this.actions;
         
    }
    deleteStepAction(stepAction) {
        this.editStep.removeStepAction(stepAction);
    }
}
export default WorkflowEditManager;