import Action from "./Action";
import DialogParameter from "./DialogParameter";
import ActionOutput from './ActionOutput';
import { extractSelectedValues } from '@/Client/modules/pbadmin/common/utils';
import { consoleLogWithSentry } from '@/Client/modules/pbadmin/observability/logging';

class StepAction {
    constructor(data) {
        this.id = data.id;
        this.companyId = data.companyId;
        this.stepId = data.stepId;
        this.action = new Action(data.action);
        this.supplementalDataCodes = Array.isArray(data.supplementalDataCodes) ? data.supplementalDataCodes.map(code => code) : [];
        this.toStepId = data.toStepId;
        this.supplementalDataDefinitions = Array.isArray(data.supplementalDataDefinitions) ? data.supplementalDataDefinitions.map(parameter => new DialogParameter(parameter, data.id)) : [];
        this.toPageRoute = data.toPageRoute;
        this.supplementalDataMaxRows = data.supplementalDataMaxRows;
        this.supplementalDataMinRows = data.supplementalDataMinRows;
        this.displayOrder = data.displayOrder;
        this.stepFilters = Array.isArray(data.stepFilters) ? data.stepFilters.map(code => code) : [];
        this.cacheSelection = data.cacheSelection;
        this.Label = this.action.actionSummary;
        this.selected = false;
        this.showoutput = false;
        this.cachedItems = new Map();
        this.searchFilter = "";

    }
    static create(data) {
        return new StepAction(data);

    }
    static createNew(stepId) {
        return new StepAction({
            id: null,
            companyId: null,
            stepId: stepId,
            action: null,
            supplementalDataCodes: [],
            toStepId: null,
            supplementalDataDefinitions: [],
            stepFilters: [],
            toPageRoute: null,
            supplementalDataMaxRows: null,
            supplementalDataMinRows: null,
            displayOrder: 0

        });

    }
    getSelectedItem(stepName) {
        if (this.cachedItems && this.cachedItems.has(stepName.toLowerCase())) {
            return this.cachedItems.get(stepName.toLowerCase());
        }
        return "";
    }
    hasDialogCode(dialogCode) {
        return this.supplementalDataCodes.includes(dialogCode);
    }
    hasSearchDialog() {
        return this.supplementalDataDefinitions.some(def => def.isSearchDialog());
    }
    unFilteredText() {
        if (this.supplementalDataDefinitions && this.supplementalDataDefinitions.length > 0) {
            let dialogOutput = "";
            let output = this.action.actionOutput;
            this.supplementalDataDefinitions.forEach(def => {
                let dialogs = def.getOutputText("");
                dialogs.forEach((value, key) => {
                    dialogOutput += value + " ";
                });
            });
            var result = dialogOutput.replace(/,/g, '');
            var label = this.makePlural(this.action.actionSummary, result);

            return result + " " + label;
        }
        return this.action.actionSummary;

    }
    makePlural(text, prefix) {
        if (prefix && (prefix.toLowerCase() == "both" || prefix.toLowerCase() == "all")) {
            if (text.endsWith('s')) {
                return text;
            }
            return text + 's';
        }
        return text;
    }
    filteredQuestion() {
        //this will only get 2 max items so a string can not have more then two {selected:(value)} entries
        var question = this.action.actionSummary;
        var selectedItems = extractSelectedValues(question);
        if (selectedItems && selectedItems.length > 0) {
            for (let i = 0; i < selectedItems.length; i++) {
                var item = this.getSelectedItem(selectedItems[i]);
                question = question.replace('{selected:' + selectedItems[i] + '}', item);
            }
        }
        this.filteredActionSummary = question.trim();
        return this.filteredActionSummary;
    }

    filteredOutput(stepCategory) {
        let output = this.action.actionOutput;
        let selectedItems = extractSelectedValues(output);

        if (selectedItems && selectedItems.length > 0) {
            selectedItems.forEach(itemValue => {
                let item = this.getSelectedItem(itemValue);
                output = output.replace(`{selected:${itemValue}}`, item);
            });
        }

        this.filteredActionOutput = output;
        let actionOutputs = [];

        if (this.supplementalDataDefinitions.length > 0) {
            this.supplementalDataDefinitions.forEach(def => {
                let dialogOutput = def.getOutputText(stepCategory);

                if (dialogOutput.size > 0) {
                    dialogOutput.forEach((value, key) => {
                        const val = output.replace('{_dialog}', value);
                        var outputOrder = this.action.outputOrder;
                        if (stepCategory.toLowerCase() != key.toLowerCase()) {

                            if (this.action.altOutputOrder > 0) {
                                outputOrder = this.action.altOutputOrder;
                            }
                        }
                        actionOutputs.push(ActionOutput.create({
                            actionOutput: val,
                            actionId: this.action.id,
                            outputOrder: outputOrder,
                            outputCategory: key
                        }));
                    });
                } else {
                    actionOutputs.push(ActionOutput.create({
                        actionOutput: output.replace('{_dialog}', ""),
                        outputOrder: this.action.outputOrder,
                        actionId: this.action.id,
                        outputCategory: stepCategory
                    }));
                }
            });
        } else {
            actionOutputs.push(ActionOutput.create({
                actionOutput: output.replace('{_dialog}', ""),
                outputOrder: this.action.outputOrder,
                actionId: this.action.id,
                outputCategory: stepCategory
            }));
        }

        return actionOutputs;
    }

    setCachedItems(cachedItemMap) {
        this.cachedItems = cachedItemMap;
    }
}
export default StepAction;
