<template>
  <div>
    <Button class="add-button mb-4" @click="addNewItem">
      <i class="pi pi-plus"></i> Add
    </Button>

    <div class="headers">
      <div class="header-item">Order</div>
      <div class="header-item">Action Summary</div>
      <div class="header-item">Action Output</div>
      <div class="header-item">Grouped</div>
      <div class="header-item">Additional data</div>
      <div class="header-item"></div>
    </div>

    <draggable class="drag-area" v-model="flatData" @end="onDragEnd" item-key="id">
      <template #item="{ element, index }">
        <div class="draggable-row" :class="{ 'row-selected': selectedIndex === index }" @click="selectRow(index)">
          <div class="draggable-column">{{ element.displayOrder }}</div>
          <div class="draggable-column large-col">{{ element.actionSummary }}</div>
          <div class="draggable-column large-col">{{ element.actionOutput }}</div>
          <div class="draggable-column">{{ element.groupIds ? "true" : "false" }}</div>
          <div class="draggable-column">{{ element.supplementalDataCodes ? "true" : "false" }}</div>
          <div class="draggable-column grid-buttons">
            <Button class="edit-button" @click="editRow(element)">
              <i class="pi pi-pencil"></i>
            </Button>
            <Button class="delete-button" @click="deleteRow(element)" severity="danger">
              <i class="pi pi-trash"></i>
            </Button>
          </div>
        </div>
      </template>
    </draggable>

    <stepActionEditor :workflowEditManager="workflowEditManager" />

    <!-- Button Bar at the Bottom -->
    <div class="button-bar d-flex justify-content-end space-between">
      <Button class="save-button" @click="saveChanges">Save</Button>
      <Button class="cancel-button" @click="cancelChanges" label="Cancel"></Button>
    </div>
  </div>
</template>



<script>
import draggable from 'vuedraggable';
import WorkflowEditManager from '@/Client/modules/pbadmin/manager/WorkflowEditManager';
import supplementalDataDialogComponent from '@/newComponents/supplementalDataComponents/supplementalDataDialogComponent.vue';
import WorkflowStep from '@/Client/modules/pbadmin/models/WorkflowStep';
 
import EventBus from '@/Client/modules/pbadmin/event_bus/EventBus.js'
import stepActionEditor from '@/newComponents/stepEditorComponents/stepActionEditorComponent.vue';
import { mapActions, mapState } from 'vuex'
import StepAction from '@/Client/modules/pbadmin/models/StepAction';

export default {
  name: 'workflowStepEditor',
  components: {
    supplementalDataDialogComponent,
    stepActionEditor,
    draggable
  },

  props: {
    step: {
      type: WorkflowStep,
      required: true
    },
    component: {
      type: Object,
      required: false,
    }
  },

  computed: {

    stepText() {
      if (this.step) {
        return this.step.text;
      }
      return "";
    },
    componentTitle() {
      return "";
    },

  },
  data() {
    return {
      flatData: [],
      workflowEditManager: null,
      selectedIndex: null,
      editAction: null
    };
  },
  watch: {

    'step.actions': {
      deep: true,
      handler(newActions, oldActions) {

        this.flatData = this.step.flatten();
        //console.log(new Date().toLocaleString() + "stepEditComponent watcher");
      }
    }
  },
  computed: {
    ...mapState({ user: state => state.persisted.user, session: state => state.persisted.session }),
  },
  created() {
    this.workflowEditManager = WorkflowEditManager.create(this.step.id, this.step);

    this.flatData = this.step.flatten()
  },
  methods: {
    ...mapActions(['setNavItems']),
    selectRow(index) {
      this.selectedIndex = index;
      this.rowSelected(this.flatData[index]);
    },
    rowSelected(selectedRow) {
      this.$emit('row-selected', selectedRow);
    },
    saveChanges() {

    },
    cancelChanges() {

    },
    editRow(rowData) {

  
      const stepAction = this.step.actions.find(stepAction => stepAction.id === rowData.id);
      if (stepAction) {
         
        EventBus.emit('show-stepActionEditor', stepAction);
      }
    },
    addNewItem() {
      const stepAction = StepAction.createNew(this.step.id);
      if (stepAction) {
        EventBus.emit('show-stepActionAdd',stepAction);
      }
    },
    deleteRow(rowData) {

      const stepAction = this.step.actions.find(stepAction => stepAction.id === rowData.id);
      if (stepAction) {
        this.workflowEditManager.deleteStepAction(stepAction);
        const filteredStepActions = this.step.actions.filter(action => action.id !== stepAction.id);
        this.step.actions = filteredStepActions;
        this.onDragEnd(null);
      }
    },
    onDragEnd(event) {
      this.$nextTick(() => {

        this.flatData.forEach((item, index) => {
          item.displayOrder = index;
        });
      });
    },
  }
}
</script>
<style scoped>
.headers,
.draggable-row {
  display: flex;
  width: 100%;
}

.headers {
  background-color: #494949;
  color: #ffffff;
  font-weight: bold;
}

.header-item,
.draggable-column {
  flex: 1;
  text-align: left;
  padding: 0.5rem;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.headers .header-item:first-child,
.draggable-row .draggable-column:first-child {
  flex: 0 0 50px;
  background-color: #3a3838;
  font-weight: bold;
  text-align: center;
}


.headers .header-item:not(:first-child),
.draggable-row .draggable-column:not(:first-child) {
  flex: 2;
  white-space: normal;
}

.draggable-row:hover {
  background-color: #5e5e5e;
}

.draggable-column {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edit-button {
  background: #007bff;
  border: 1px solid #0056b3;
  border-radius: 0.25rem;
  color: white;
  cursor: pointer;
  display: inline-flex;
  align-items: right;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  margin-right: 15px;
  text-align: center;
  vertical-align: middle;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.delete-button {
  background: #ff0000;
  border: 1px solid #b30c00;
  border-radius: 0.25rem;
  color: white;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.edit-button .pi {
  font-size: 1rem;
}

.edit-button:hover {
  background-color: #0056b3;
  border-color: #004085;
}

.grid-buttons {
  display: flex;
  justify-content: right;
}

.large-col {
  flex: 2 0 100px;
}

.mb-4 {
  margin-bottom: 1rem;
  /* Adjust as per your spacing preference */
}

.button-bar {
  margin-top: 1rem;
  /* Top margin for spacing */
}

.button-bar .save-button,
.button-bar .cancel-button {
  margin-left: 0.5rem;
  /* Spacing between buttons */
}

.d-flex {
  display: flex;
}

.justify-content-end {
  justify-content: flex-end;
}

.space-between {
  gap: 1rem;
  /* Adjust the gap between buttons */
}</style>

