import StepAction from "./StepAction";
import {consoleLogWithSentry} from '@/Client/modules/pbadmin/observability/logging';

class WorkflowStep {
    constructor(data) {
        this.id = data.id;
        this.stepText = data.stepText;
        this.stepName = data.stepName;
        this.actions = Array.isArray(data.actions) ? data.actions.map(action => StepAction.create(action)) : [];
        this.createdByCompanyId = data.createdByCompanyId;
        this.displayOrder = data.displayOrder;
        this.stepType = data.stepType;
        this.fromStep = null;
        this.stepCategory = data.stepCategory;
        this.parentStepName = data.parentStepName;
        this.stepSelectedInSession = data.stepSelectedInSession;
        this.stepOutputCategory = data.stepOutputCategory;
        this.cachedItems = new Map();
    }
    static create(data) {
        return new WorkflowStep(data);
    }
    
    isSingleSelect() {
        return this.stepType === 0;
    }
    getNextStepId() {
        //need to scan for any selected actions that might have a selected postion dialog, that will dictate the next few steps
        return this.actions[0].toStepId;
    }
    selectedActions() {
        return this.actions.filter(action => action.selected && action.showoutput);
    }

    selectedOutput(){
        let output = new Map();
         
        if (!this.stepOutputCategory || this.stepOutputCategory == null || this.stepOutputCategory == undefined) {
            consoleLogWithSentry(this.stepName + " has no output category!");
            return output;
        }
        
        return this.selectedActions().flatMap(action => action.filteredOutput(this.stepOutputCategory));
    }
    hasMoreSteps() {
        return this.actions.some((stepAction) => stepAction.toStepId !== null)
    }
    getNextStepID() {
        const seletectedAction = this.actions.find(stepAction => stepAction.selected);
        if (seletectedAction) {
            return seletectedAction.toStepId;
        }
        return null;
}
    getStepTitle() {
        let component = "";
        let system = "";
     
           system = this.getSelectedItem("system")
           component = this.getSelectedItem("component")
       
        if (this.stepName.toLowerCase() === 'system') {
            return this.stepText;
        }
        if (this.stepName.toLowerCase() === 'component') {
            return system + ": " + this.stepText;
        }
        if (this.isSingleSelect()) {
            return this.stepText;
        }
        if (component) {
            return component + ": " + this.stepText;
        }
        return this.stepText;

    }
    removeStepAction(stepAction) {
        this.actions = this.actions.filter(action => action.id !== stepAction.id);
    }
    flatten() {
        return this.actions.map(stepToAction => ({
            id: stepToAction.id,
            actionSummary: stepToAction.action.actionSummary,
            actionOutput: stepToAction.action.actionOutput,
            groupIds: stepToAction.action.groupIds.join(', '),
            supplementalDataCodes: stepToAction.supplementalDataCodes.join(', '),
            displayOrder: stepToAction.displayOrder
        })).sort((a, b) => a.displayOrder - b.displayOrder);
    }
    getSelectedItem(stepName) {
        if (this.cachedItems && this.cachedItems.has(stepName.toLowerCase() )) {
            return this.cachedItems.get(stepName.toLowerCase() );
       }
        return "";
    }
    setCachedItems(cachedItemMap){
        this.cachedItems = cachedItemMap;
        this.actions.forEach(action => {
            action.setCachedItems(cachedItemMap);
        });
    }
}

export default WorkflowStep;
