import Card from "./Card";
import WorkflowStep from "./WorkflowStep";
 

class Workflow {
  constructor(data) {
    this.id = data.id;
    this.name = data.workflowName;
    this.steps = (data.step || []).map(step => new WorkflowStep(step));
    
    this.companyid = data.companyId;
   
    this.priorTransition = [];
    this.onStepIndex = 0;
    this.startingStepId = data.startingStepId;
  }
  asCard(){

    return new Card({
        id: this.id,
        name: this.name,
        description: this.name,
        workflows: [],
        isCompany: (this.companyid !== null && this.companyid !== undefined),
        
    });
  }
}

export default Workflow;
