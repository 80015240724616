export function getMFGInfo() {
    return [{
      "make": "Toyota", "models": [{ "model": "Camry" }, { "model": "Corolla" }, { "model": "RAV4" }, { "model": "Tacoma" }, { "model": "Sienna" }]
    },
    {
      "make": "Honda",
      "models": [
        { "model": "Accord" },
        { "model": "Civic" },
        { "model": "CR-V" },
        { "model": "Pilot" },
        { "model": "Odyssey" }
      ]
    },
    {
      "make": "Ford",
      "models": [
        { "model": "F-150" },
        { "model": "Escape" },
        { "model": "Explorer" },
        { "model": "Focus" },
        { "model": "Mustang" }
      ]
    },
    {
      "make": "Chevrolet",
      "models": [
        { "model": "Silverado" },
        { "model": "Equinox" },
        { "model": "Traverse" },
        { "model": "Camaro" },
        { "model": "Impala" }
      ]
    },
    {
      "make": "Nissan",
      "models": [
        { "model": "Altima" },
        { "model": "Sentra" },
        { "model": "Rogue" },
        { "model": "Frontier" },
        { "model": "Pathfinder" }
      ]
    }
    ]
  
  }