 
<template #container="{ closeCallback }">
<span class="control-label">Replaced or Repaired Component</span>
    <div class="list-box-container">
       <AutoComplete v-if="treeNodes" v-model="currentStepAction.searchFilter" dropdown
            :suggestions="filteredList" @complete="search" optionLabel="label" optionGroupLabel="label"
            optionGroupChildren="items" v-on:item-select="loadStepInfo" >
            <template #optiongroup="slotProps">
                <div class="flex align-items-center">
                    <div>{{ slotProps.item.label }}</div>
                </div>
            </template>
        </AutoComplete>
        <Skeleton v-if="!treeNodes" width="10rem" height="4rem"></Skeleton>

    </div>
    <div v-if="currentStepAction.searchFilter.value" v-for="(dialogParameter, index) in currentStepAction.searchFilter.value.supplementalDataDefinitions"
        :key="index" class="supplemental-container">
        <supplementalDataDialogComponent :dialogParameter="dialogParameter" :currentStepAction="currentStepAction.searchFilter.value" />
    </div>
</template>
 

<script>
import { getStepActionsWithChildren } from '@/Client/modules/pbadmin/services/WorkflowStepService';
import { FilterMatchMode, FilterService } from 'primevue/api';
import supplementalDataDialogComponent from '@/newComponents/supplementalDataComponents/supplementalDataDialogComponent.vue';
import EventBus from '@/Client/modules/pbadmin/event_bus/EventBus.js'

export default {
    components: {
        supplementalDataDialogComponent
    },

    props: {
        currentStepAction: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            selectedSystem: null,
            filteredList: null,
            selectedComponent: null,
            treeNodes: null,
            stepActionList: null,
            componentList: [],
            visible: true,

        };
    },
   //needs events to trigger a clearing of all steps prior to component
   //needs to clear cache and readd new selection

    mounted() {
        this.init();
    },

    computed: {


    },

    watch: {
        watch: {
            'currentStepAction.searchFilter': {
                immediate: true,
                handler(newVal) {
        
                    this.selectedComponent=newVal.value;
                }
            },
        },
    },

    methods: {
        init() {
            if (this.currentStepAction) {
                getStepActionsWithChildren(this.currentStepAction.toStepId).then((stepActions) => {
                    this.stepActionList = stepActions;
                    this.treeNodes = this.stepActionList.getParentChildList();
                });

            }
        },
        triggerLoadStepInfo(searchFilterValue) {

            let foundItem = null;
            for (let system of this.treeNodes) {
                foundItem = system.items.find(item => item.label === searchFilterValue);
                if (foundItem) break;
            }

            if (foundItem) {
                this.loadStepInfo({ value: foundItem });
            }
        },
        search(event) {
            let query = event.query;
            let filteredSystems = [];

            for (let system of this.treeNodes) {
                let filteredItems = FilterService.filter(system.items, ['label'], query, FilterMatchMode.CONTAINS);

                if (filteredItems && filteredItems.length) {
                    filteredSystems.push({ ...system, ...{ items: filteredItems } });
                }
            }

            this.filteredList = filteredSystems;
        },
        loadStepInfo(item) {
            var selected = item.value.value;
            this.selectedComponent = selected;
            this.selectedSystem = item.value.parent;
            EventBus.emit('cache-selection', { selectedComponent: this.selectedComponent, selectedSystem: this.selectedSystem });
            EventBus.emit('reset-history', this.currentStepAction);
        },
    },
};
</script>
<style scoped>
.list-box-container {
    display: flex;
    gap: 20px;
    justify-content: left;
    padding: 10px;
    max-width: 50dvh;
    max-height: 20dvh;
    overflow-y: auto;
}

.p-input-icon-left {

    max-width: 500px !important;
}
.control-label {
  display: inline-block;
  margin-left: .1em;
  line-height: 2;
  align-items: center;
  font-weight: 600!important;
} 
/* Add more styles here if needed */
</style>
