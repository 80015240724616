class Company {
    constructor(data) {
        this.id = data.id;
        this.companyDescription = data.companyDescription;
        this.companyName = data.companyName;
        this.isActive = data.isActive;
        this.logoName = data.logoName;
    }
}

export default Company;